import BookingRequest from '../types/booking-request';
import Email from '../types/email';

const CLEVELAND_API_HEADERS = {
  Authorization: process.env.REACT_APP_CLEVELAND_GOWER_API_KEY || '',
  'Content-Type': 'application/json'
};

const AWS_HEADERS = {
  'x-api-key': process.env.REACT_APP_AWS_API_GATEWAY_KEY
};

export class ApiService {

  static approveBooking(body: any): Promise<any>  {
    const url=`${process.env.REACT_APP_AWS_SERVICE_TIER}/approveBookingRequest`;
    return this._post(url, body, AWS_HEADERS);
  }

  // TODO: Batch reminder service, reports, fix SES emails to junk folder. Load banned list from S3 bucket.

  static createBooking(booking: any): Promise<any>  {
    const url=`${process.env.REACT_APP_AWS_SERVICE_TIER}/createBooking`;
    return this._post(url, booking, AWS_HEADERS);
  }

  static async createPaymentIntent(payment: any): Promise<any> {
    const url=`${process.env.REACT_APP_AWS_SERVICE_TIER}/createStripePayment`;
    return this._post(url, payment, AWS_HEADERS);
  }

  static getBooking(bookingReference: string): Promise<any>  {
    const url=`${process.env.REACT_APP_AWS_SERVICE_TIER}/getBooking?bookingReference=${bookingReference}`;
    return this._get(url, AWS_HEADERS);
  }

  static getQuotation(quoteReference: string): Promise<any>  {
    const url = `${process.env.REACT_APP_AWS_SERVICE_TIER}/getQuotation?quoteReference=${quoteReference}`;
    return this._get(url, AWS_HEADERS);
  }

  static getReport(report: string): Promise<any>  {
    const url = `${process.env.REACT_APP_SERVICE_TIER}/admin/reports/${report}`;
    return this._get(url);
  }

  static listGoogleCalendarEvents(): Promise<any>  {
    const url=`${process.env.REACT_APP_AWS_SERVICE_TIER}/listGoogleCalendarEvents`;
    return this._get(url, AWS_HEADERS);
  }

  static loadWeeklyRates(): Promise<any>  {
    const url = `${process.env.REACT_APP_AWS_S3_BUCKET}/weekly-rates.json`;
    return this._get(url, AWS_HEADERS);
  }

  static payBalance(booking: any): Promise<any>  {
    const url=`${process.env.REACT_APP_AWS_SERVICE_TIER}/payBalance`;
    return this._post(url, booking, AWS_HEADERS);
  }

  static sendBookingRequest(bookingRequest: BookingRequest): Promise<any>  {
    const url=`${process.env.REACT_APP_AWS_SERVICE_TIER}/sendBookingRequest`;
    return this._post(url, bookingRequest, AWS_HEADERS);
  }

  static sendMessage(message: Email): Promise<any>  {
    const email = {
      to: message.to,
      from: message.from,
      subject: message.subject,
      message: message.body
    };

    const url = `${process.env.REACT_APP_AWS_SERVICE_TIER}/sendEmail`;
    return this._post(url, email, AWS_HEADERS);
  }

  static _get(url: string, headers: any = CLEVELAND_API_HEADERS): Promise<any>  {
    return fetch(url, { method: 'GET', headers });
  }

  static _post(url: string, body: any, headers: any = CLEVELAND_API_HEADERS): Promise<any>  {
    return fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });
  }
}
