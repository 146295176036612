import { ReactElement } from 'react';

const BookingNotAvailable = (): ReactElement<string, string> => {
  return (
    <div className="content --padding-md">
      <h1>Holiday not available</h1>
      <p>Sorry, your chosen holiday is no longer available.</p>
      <p>Go to the <a href="/bookings">Bookings</a> page</p>
      <p>Go to the <a href="/">Home</a> page</p>
    </div>
  );
};

export default BookingNotAvailable;
