import { ReactElement } from 'react';

import './textarea.scss';

type TextareaProps = {
  label: string,
  id: string,
  name: string,
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  value: string,
  optional?: boolean,
  rows: number,
  error?: string
}

const Textarea = (props: TextareaProps): ReactElement<string, string> => {
  return (
    <div className="textarea">
      <label className="textarea__label" htmlFor="name">{props.label}{props.optional ? ' (optional)' : ''}:</label>

      <textarea
        className={`textarea__input ${props.error ? 'textarea__input--error' : ''} --margin-top-sm`}
        id={props.id}
        name={props.name}
        onChange={props.handleChange}
        value={props.value}
        rows={5}
      />

      {props.error && <div className='textbox__error'>{props.error}</div>}

    </div>
  );
};

export default Textarea;
