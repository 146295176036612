import { ReactElement, useEffect, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPrefix } from '@fortawesome/fontawesome-common-types';

import { ApiService } from '../../services/api.service';
import BookingCalendarHeader from './booking-calendar-header';
import BookingCalendarColumnHeadings from './booking-calendar-column-headings';
import BookingCalendarRow from './booking-calendar-row';
import BookingCalendarFooter from './booking-calendar-footer';

import { Constants } from '../../utilities/constants';

import { Day } from '../../models/day';
import { getCalendarWeeks, createCalendarHeading, createDays, calcHasPreviousMonth, calcHasNextMonth } from './calendar-utilities';

import './booking-calendar.scss';

type BookingCalendarProps = {
  selectPeriod: (day: Day) => void;
  clearSelection: () => void;
  setCalendar: (days: Map<string, Day>) => void;
}

const BookingCalendar = (props: BookingCalendarProps): ReactElement<string, string> => {
  const [isLoading, setIsLoading] = useState(true);
  const [bookings, setBookings] = useState(null);
  const [weeklyRates, setWeeklyRates] = useState(null);
  const [days, setDays] = useState<Map<string, Day>>(null);
  const [calendarHeading, setCalendarHeading] = useState('');
  const [hasPreviousMonth, setHasPreviousMonth] = useState(true);
  const [hasNextMonth, setHasNextMonth] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [weeks, setWeeks] = useState<Day[][]>([]);

  useEffect(() => {
    setDays(createDays());
  }, []);

  useEffect(() => {
    const loadBookings = async () => {
      const data = await ApiService.listGoogleCalendarEvents();
      const json = await data.json();
      setBookings(json);
    };

    loadBookings()
      .catch(console.error);
  }, []);

  useEffect(() => {
    const now = moment();
    const currentMonth = now.month();
    const currentYear = now.year();

    setSelectedMonth({
      month: currentMonth,
      year: currentYear
    });
  }, []);

  useEffect(() => {
    const loadWeeklyRates = async () => {
      const result = await ApiService.loadWeeklyRates();

      if (result.status !== 200) {
        console.error('Error getting weekly rates:', result);
        // TODO redirect to error screen
      } else {
        const rates = await result.json();

        setWeeklyRates(rates);
      }
    };

    if (!weeklyRates) {
      loadWeeklyRates()
        .catch(console.error);
    }
  }, []);

  useEffect(() => {
    const now = moment();
    const currentMonth = now.month();
    const currentYear = now.year();

    setSelectedMonth({
      month: currentMonth,
      year: currentYear
    });
  }, []);

  useEffect(() => {
    const setCalendarRates = () => {
      for (const period of weeklyRates) {

        if (days) {
          let day = days.get(period.from);
          if (day) {
            day.weeklyRate = Number(period.rate);
            for (let i = 0; i < 6; i++) {
              day = day.next;
              day.weeklyRate = Number(period.rate);
            }
          }
        }
      }
    };

    if (weeklyRates && weeklyRates.length) {
      setCalendarRates();
    }
  }, [weeklyRates]);

  useEffect(() => {
    const setUnavailableDays = () => {
      if (days) {
        for (const day of Constants.DISABLED_DAYS) {
          days.get(day).isAvailable = false;
        }

      }
    };

    setUnavailableDays();
  }, [days]);

  useEffect(() => {
    if (bookings && days) {
      bookings.forEach((booking: any) => {
        const bookingStart = days.get(moment(booking.start.date, Constants.CALENDAR_DATE_PICKER_FORMAT).format(Constants.CALENDAR_KEY_FORMAT));
        const bookingEnd = days.get(moment(booking.end.date, Constants.CALENDAR_DATE_PICKER_FORMAT).format(Constants.CALENDAR_KEY_FORMAT));

        if (bookingStart && bookingEnd) {
          let day = bookingStart;
          while (day.key < bookingEnd.key) {
            day.isAvailable = false;
            day = day.next;
          }
          bookingEnd.isChangeover = true;
        }
      });

      props.setCalendar(days);

      setIsLoading(false);
    }

  }, [bookings, days]);

  useEffect(() => {
    if (selectedMonth) {
      setCalendarHeading(createCalendarHeading(selectedMonth.month, selectedMonth.year));
      setHasPreviousMonth(calcHasPreviousMonth(selectedMonth.month, selectedMonth.year));
      setHasNextMonth(calcHasNextMonth(selectedMonth.month, selectedMonth.year));

      const calendarWeeks = getCalendarWeeks(selectedMonth.month, selectedMonth.year, days);
      setWeeks(calendarWeeks);
    }
  }, [selectedMonth]);

  const selectNextMonth = () => {
    if (selectedMonth) {
      const month = selectedMonth.month < 11 ? selectedMonth.month + 1 : 0;
      const year = selectedMonth.month < 11 ? selectedMonth.year : selectedMonth.year + 1;
      selectMonth(month, year);
    }
  };

  const selectPreviousMonth = () => {
    if (selectedMonth) {
      const month = selectedMonth.month > 0 ? selectedMonth.month - 1 : 11;
      const year = selectedMonth.month > 0 ? selectedMonth.year : selectedMonth.year - 1;
      selectMonth(month, year);
    }
  };

  const selectMonth = (month: number, year: number) => {
    setSelectedMonth({
      month,
      year
    });
    props.clearSelection();
  };

  return (
    <>
      <div className='--align-center'>
        {isLoading ? (
          <div className="--align-center --margin-top-xl">
            <h1>Loading calendar - please wait</h1>
            <p>
              <FontAwesomeIcon
                icon={['fas' as IconPrefix, 'cog']}
                size="4x"
                className="spinner" />
            </p>
          </div>
        ) : (
          <>
            <div className="booking-calendar">

              <BookingCalendarHeader
                calendarHeading={calendarHeading}
                hasPreviousMonth={hasPreviousMonth}
                hasNextMonth={hasNextMonth}
                selectPreviousMonth={selectPreviousMonth}
                selectNextMonth={selectNextMonth}
              />

              <BookingCalendarColumnHeadings />

              {weeks.map((week: Day[], i: number) =>
                <BookingCalendarRow
                  week={week}
                  selectPeriod={props.selectPeriod}
                  key={i}
                />
              )}

              <BookingCalendarFooter />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BookingCalendar;
