import * as types from '../actions/action-types';

const INITIAL_STATE: any = {
	calendarDays: null,
  selectedHoliday: null,
	completedBooking: null
};

export default function createReducer(state: any = INITIAL_STATE, action: any): any {
  switch(action.type) {
		case types.SET_CALENDAR:
				return {
					...state,
					calendarDays: action.days
				};
    case types.SELECT_HOLIDAY:
			return {
				...state,
				selectedHoliday: action.selectedHoliday
			};
    case types.SAVE_COMPLETED_BOOKING:
			return {
				...state,
				completedBooking: action.completedBooking
			};
    default:
      return state;
  }
}
