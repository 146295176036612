import { ReactElement, useState } from 'react';
import {
  Link,
  useLocation
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

import { Constants } from '../../utilities/constants';
import logo from '../../assets/images/logo.svg';

import './navigation-bar.scss';
import SlidingMenu from '../sliding-menu/sliding-menu';


const NavigationBar = (): ReactElement<string, string> => {
  const location = useLocation();
  const [navOpen, setNavOpen] = useState(false);

  const toggleMenu = () => {
    setNavOpen(!navOpen);
  };

  return (
    <nav className="navigation-bar">
      <a href="/"><img src={logo} className="navigation-bar__app-logo" alt="logo" /></a>
      <a href="/"><h1 className="navigation-bar__company-name">Cleveland Holiday Cottage</h1></a>
      <div className="navigation-bar__list">
        <ul>
          <li className={"navigation-bar__list-item" + (location.pathname === Constants.Routes.HOME ? ' navigation-bar__list-item--selected' : '')}><Link to={Constants.Routes.HOME}>Home</Link></li>
          <li className={"navigation-bar__list-item" + (location.pathname === Constants.Routes.BOOKINGS ? ' navigation-bar__list-item--selected' : '')}><Link to={Constants.Routes.BOOKINGS}>Bookings</Link></li>
          <li className={"navigation-bar__list-item" + (location.pathname === Constants.Routes.ABOUT ? ' navigation-bar__list-item--selected' : '')}><Link to={Constants.Routes.ABOUT}>About</Link></li>
          <li className={"navigation-bar__list-item" + (location.pathname === Constants.Routes.GALLERY ? ' navigation-bar__list-item--selected' : '')}><Link to={Constants.Routes.GALLERY}>Gallery</Link></li>
          <li className={"navigation-bar__list-item" + (location.pathname === Constants.Routes.CONTACT ? ' navigation-bar__list-item--selected' : '')}><Link to={Constants.Routes.CONTACT}>Contact</Link></li>
          <li className={"navigation-bar__list-item" + (location.pathname === Constants.Routes.HOW_TO_FIND_US ? ' navigation-bar__list-item--selected' : '')}><Link to={Constants.Routes.HOW_TO_FIND_US}>How to find us</Link></li>
        </ul>
      </div>

      <div className="mobile-nav" onClick={toggleMenu}>
        {navOpen ?
          <div className="mobile-nav__icon">
            <FontAwesomeIcon icon={['fas' as IconPrefix, 'xmark' as IconName]} size="2x" />
          </div> :
          <div className="mobile-nav__icon mobile-nav__icon--bump-right">
            <FontAwesomeIcon icon={['fas' as IconPrefix, 'bars' as IconName]} size="2x" />
          </div>}
        <div className="mobile-nav__text">{navOpen ? 'CLOSE' : 'MENU'}</div>
      </div>

      <SlidingMenu
        navOpen={navOpen}
        toggleMenu={toggleMenu}
      />
    </nav >
  );
};

export default NavigationBar;
