import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPrefix } from '@fortawesome/fontawesome-common-types';

import { ApiService } from '../../services/api.service';
import { Constants } from '../../utilities/constants';
import BookablePeriod from '../../components/bookable-period/bookable-period';
import InfoBanner from '../../components/info-banner/info-banner';
import Overlay from '../../components/overlay/overlay';
import PaymentForm from '../../components/payment-form/payment-form';

import './pay-balance.scss';


const PayBalance = (): ReactElement<string, string> => {
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [initialPayment, setInitialPayment] = useState(null);
  const [finalPayment, setFinalPayment] = useState(null);
  const [paymentDesciption, setPaymentDesciption] = useState('');

  const [completedPayment, setCompletedPayment] = useState(false);
  const [processing, setProcessing] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    ApiService.getBooking(id)
      .then((result) => {
        if (result.status !== 200) {
          navigate(Constants.Routes.BOOKING_NOT_FOUND);
        } else {
          result.json().then((booking: any) => setBooking(booking));
        }
      }).catch(error => {
        console.error(error);
        navigate(Constants.Routes.GENERAL_ERROR);
      });
  }, []);

  useEffect(() => {
    if (booking) {
      const dateFormatted = moment(booking.booking.arrivalDate, Constants.CALENDAR_KEY_FORMAT).format(Constants.CALENDAR_LONG_DATE_FORMAT);
      const description = `Cleveland Holiday Cottage - ${booking.booking.duration} nights from ${dateFormatted}. Remaining balance.`;

      setInitialPayment(booking.payment.initialPayment);
      setFinalPayment(booking.payment.finalPayment);
      setPaymentDesciption(`${description}`);
    }
  }, [booking]);

  useEffect(() => {
    if (completedPayment) {
      const balancePayment: any = {
        bookingReference: booking.bookingReference
      };

      const bookingResult: any = {};

      ApiService
        .payBalance(balancePayment)
        .then((result) => {
          if (result.status !== 200) {
            navigate(Constants.Routes.GENERAL_ERROR);
          }
        })
        .catch((response) => {
          bookingResult.message = response.error.message;
          bookingResult.failure = true;
        });

      // TODO handle error?
      handleStopProcessing();
    }

  }, [completedPayment]);

  const handlePaymentComplete = async () => {
    setCompletedPayment(true);
  };

  const handleStartProcessing = () => {
    setProcessing(true);
  };

  const handleStopProcessing = () => {
    setProcessing(false);
  };

  return (
    <>
      {!booking &&
        <Overlay
          innerHtml={
            <>
              <div className="--align-center">
                <h1 >Loading booking - please wait</h1>
                <p>
                  <FontAwesomeIcon
                    icon={['fas' as IconPrefix, 'cog']}
                    size="4x"
                    className="spinner" />
                </p>
              </div>
            </>}
        />
      }

      {processing &&
        <Overlay
          innerHtml={
            <>
              <div className="--align-center">
                <h1 >Processing payment - please wait</h1>
                <p>
                  <FontAwesomeIcon
                    icon={['fas' as IconPrefix, 'cog']}
                    size="4x"
                    className="spinner" />
                </p>
              </div>
            </>}
        />
      }

      <div className="content --padding-md">
        {booking && initialPayment && !finalPayment && !completedPayment && !(initialPayment && initialPayment.payInFull) &&
          <>
            <h1>PAY BALANCE</h1>

            <h2 className="no-padding">YOUR HOLIDAY</h2>

            <BookablePeriod
              arrivalDate={moment(booking.booking.arrivalDate, Constants.CALENDAR_KEY_FORMAT).format(Constants.CALENDAR_LONG_DATE_FORMAT)}
              departureDate={moment(booking.booking.departureDate, Constants.CALENDAR_KEY_FORMAT).format(Constants.CALENDAR_LONG_DATE_FORMAT)}
              duration={booking.booking.duration}
              rate={booking.booking.rate}
            />

            <div className="form-row">
              <h2 className="no-padding">PAYMENT</h2>
              <div className="payment-section">
                <p>Total cost: £{booking.booking.rate}</p>
                <p>Amount paid: £{booking.payment.initialPayment.amountPaid}</p>
                <p>Amount remaining: £{booking.payment.initialPayment.amountRemaining}</p>
              </div>
            </div>

            <PaymentForm
              amount={initialPayment.amountRemaining}
              paymentDesciption={paymentDesciption}
              onPaymentComplete={handlePaymentComplete}
              onStartProcessing={handleStartProcessing}
              onStopProcessing={handleStopProcessing}
            />
          </>
        }

        {completedPayment &&
          <InfoBanner
            innerHtml={
              <>
                <h1>Final payment received - booking paid in full</h1>
                <h2 className="--align-left --margin-top-lg">Thank you for your final payment! Here are your booking details:</h2>
                <div className="--margin-top-lg">
                  <table className="--align-left">
                    <tbody>
                      <tr><td className="--padding-right-lg">Booking reference:</td><td>{booking.bookingReference}</td></tr>
                      <tr><td className="--padding-right-lg">Arrival date:</td><td>{moment(booking.booking.arrivalDate, Constants.CALENDAR_KEY_FORMAT).format(Constants.CALENDAR_LONG_DATE_FORMAT)}</td></tr>
                      <tr><td className="--padding-right-lg">Departure date:</td><td>{moment(booking.booking.departureDate, Constants.CALENDAR_KEY_FORMAT).format(Constants.CALENDAR_LONG_DATE_FORMAT)}</td></tr>
                      <tr><td className="--padding-right-lg">Duration:</td><td>{booking.booking.duration} nights</td></tr>
                    </tbody>
                  </table>
                </div>
                <p className="--align-left --margin-top-lg">We will send confirmation of your final payment to the email address that you supplied ({booking.customer.email})</p>

              </>}
          />
        }

        {(finalPayment || (initialPayment && initialPayment.payInFull)) &&
          <InfoBanner
            innerHtml={
              <>
                <h1>Booking paid in full</h1>
                <h2 className="--align-left --margin-top-lg">Here are your booking details:</h2>
                <div className="--margin-top-lg">
                  <table className="--align-left">
                    <tbody>
                      <tr><td className="--padding-right-lg">Booking reference:</td><td>{booking.bookingReference}</td></tr>
                      <tr><td className="--padding-right-lg">Arrival date:</td><td>{moment(booking.booking.arrivalDate, Constants.CALENDAR_KEY_FORMAT).format(Constants.CALENDAR_LONG_DATE_FORMAT)}</td></tr>
                      <tr><td className="--padding-right-lg">Departure date:</td><td>{moment(booking.booking.departureDate, Constants.CALENDAR_KEY_FORMAT).format(Constants.CALENDAR_LONG_DATE_FORMAT)}</td></tr>
                      <tr><td className="--padding-right-lg">Duration:</td><td>{booking.booking.duration} nights</td></tr>
                    </tbody>
                  </table>
                </div>
              </>}
          />
        }
      </div>
    </>
  );
};

export default PayBalance;
