import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

const Gallery = (): ReactElement<string, string> => {

  return (
    <div className="content --padding-md">
      <h1>Guest information</h1>
      <p>Click this link to read our <Link to="/files/welcome-pack.pdf" target="_blank" rel="noopener noreferrer">Welcome Pack</Link></p>
    </div>
  );
};

export default Gallery;
