import { ReactElement, useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';

import 'react-image-gallery/styles/css/image-gallery.css';
import './gallery-component.scss';

type GalleryProps = {
  galleryConfig: any
}

const GalleryComponent = (props: GalleryProps): ReactElement<string, string> => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const imageData = props.galleryConfig.galleryItems.map((item: any) => {

      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const image = require(`../../../public/gallery-images/${item.filename}`);

      return {
        original: `${process.env.REACT_APP_URL}${image}`,
        thumbnail: `${process.env.REACT_APP_URL}${image}`,
        originalHeight: '1000px'
      };
    });

    setImages(imageData);
  }, []);

  return (
    <>
      <ImageGallery items={images} />
    </>
  );
};

export default GalleryComponent;
