import { useState, useEffect, ReactElement } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import NumberFormat from 'react-number-format';

import InfoBanner from '../info-banner/info-banner';
import { ApiService } from '../../services/api.service';

import './payment-form.scss';

type PaymentFormProps = {
  amount: number,
  paymentDesciption: string,

  onPrevious?: () => void,
  onPaymentComplete: () => void,
  onStartProcessing: () => void,
  onStopProcessing: () => void
}

const PaymentForm = (props: PaymentFormProps): ReactElement<string, string> => {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [invalidPaymentAmount, setInvalidPaymentAmount] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (props.amount < 0.30) {
      setInvalidPaymentAmount(true);
    } else {
      ApiService.createPaymentIntent({
        amount: Math.round(props.amount * 100),
        description: props.paymentDesciption
      })
        .then((results) => {
          results.json().then((data: any) => {
            setClientSecret(data['client_secret']);
          });
        });
    }

  }, []);

  const handleChange = async (event: any) => {
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setError(false);
    setProcessing(true);
    props.onStartProcessing();

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });

    if (payload.error) {
      setError(`${payload.error.message}`);
      props.onStopProcessing();
      setProcessing(false);
    } else {
      setError(false);

      // TODO confirm
      // props.stopProcessing();

      props.onPaymentComplete();
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '18px',
      },
      invalid: {
        color: '#fa755a',
        fontSize: '18px',
      }
    }
  };

  return (
    <>
      {invalidPaymentAmount &&
        <InfoBanner
          isError={true}
          innerHtml={
            <>
              <p>Invalid payment amount</p>
              <p>Stripe payments must be at least £0.30</p>
            </>}
        />
      }

      {!invalidPaymentAmount &&
        <>
          <div className="payment-form__description">
            <p>Description: {props.paymentDesciption}</p>
            <p className='--bold'>Amount to pay:
              <span className='--padding-left-xs'>
                <NumberFormat
                  value={props.amount}
                  thousandSeparator={true}
                  displayType={'text'}
                  prefix={'£'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </span>
            </p>
          </div>

          <form id="payment-form" onSubmit={handleSubmit}>

            <div className="payment-form__card-details --margin-top-lg --padding-md">

              <h3>Enter your card details below</h3>

              <div className="--form-group --margin-bottom-md">
                <div className="--form-group --margin-bottom-sm">
                  Card number:
                </div>
                <CardNumberElement
                  options={cardElementOptions}
                  onChange={handleChange}
                />
              </div>

              <div className="--form-group --margin-bottom-md">
                <div className="--form-group --margin-bottom-sm">
                  Card CVC (3 digit security code):
                </div>
                <CardCvcElement
                  options={cardElementOptions}
                  onChange={handleChange}
                />
              </div>

              <div className="--form-group --margin-bottom-md">
                <div className="--form-group --margin-bottom-sm">
                  Card expiry date:
                </div>
                <CardExpiryElement
                  options={cardElementOptions}
                  onChange={handleChange}
                />
              </div>

              {!processing && <>
                <div className='--align-center'>
                  {error &&
                    <InfoBanner
                      isError={true}
                      innerHtml={
                        <>
                          <p>Sorry - there was an error processing your payment.</p>
                          <p>{error}</p>
                        </>}
                    />
                  }
                </div>
              </>}
            </div>

            <div>
              <div className="--margin-top-lg --align-right">
                {!processing &&
                  <>
                    {props.onPrevious &&
                      <button
                        type="button"
                        className={"button button--secondary --margin-right-md"}
                        onClick={props.onPrevious}>Previous step</button>
                    }

                    <button
                      type="submit"
                      className={"button button--primary"}>Pay now</button>
                  </>
                }

                {processing && <div>Processing payment...</div>}
              </div>
            </div>
          </form>

          {/* {!processing && <>
            <div className='--align-center'>
              {error &&
                <InfoBanner
                  isError={true}
                  innerHtml={
                    <>
                      <p>Sorry - there was an error processing your payment.</p>
                      <p>{error}</p>
                    </>}
                />
              }
            </div>
          </>} */}
        </>
      }
    </>

  );
};

export default PaymentForm;
