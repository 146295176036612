export class Constants {

  // Saturday changeover
  public static CHANGEOVER_DAY = 6;

  public static YEAR_FROM = 2021;
  public static YEAR_TO = 2024;

  public static DISABLED_DAYS = [
    '20250104',
    '20250105',
  ];

  public static CLEVELAND_GOWER_EMAIL = 'clevelandgower@gmail.com';

  public static CALENDAR_KEY_FORMAT = 'YYYYMMDD';
  public static CALENDAR_SHORT_DATE_FORMAT = 'DD/MM/YYYY';
  public static CALENDAR_LONG_DATE_FORMAT = 'dddd Do MMMM YYYY';
  public static CALENDAR_MONTH_YEAR_FORMAT = 'MMMM YYYY';
  public static CALENDAR_DATE_PICKER_FORMAT = 'YYYY-MM-DD';


  public static Routes = {
    ABOUT: '/about',
    APPROVE_BOOKING: '/admin/approve-booking/:id',
    AWAITING_APPROVAL: '/bookings/custom-booking/awaiting-approval',
    BOOKINGS: '/bookings',
    BOOKING_NOT_AVAILABLE: '/booking/no-longer-available',
    BOOKING_REJECTED: '/bookings/custom-booking/booking-rejected',
    BOOKING_NOT_FOUND: '/booking-not-found',
    BOOKING_COMPLETE: '/booking-complete',
    CONTACT: '/contact',
    CREATE_BOOKING: '/create-booking',
    CUSTOM_BOOKING: '/bookings/custom-booking/:id',
    GALLERY: '/gallery',
    GUEST_INFORMATION: '/guest-information',
    GENERAL_ERROR: '/something-went-wrong',
    HOME: '/',
    HOW_TO_FIND_US: '/how-to-find-us',
    PAY_BALANCE: '/pay-balance/:id',
    REPORT_CURRENT_BOOKINGS: '/admin/reports/current-bookings',
    REPORT_YEARLY_OCCUPANCY: '/admin/reports/yearly-occupancy'
  };

  public static getMultiplier(duration: number): number {
    let multiplier;

    if (duration <= 3) {
      multiplier = 0.6;
    } else if (duration === 4) {
      multiplier = 0.7;
    } else if (duration === 5) {
      multiplier = 0.8;
    } else if (duration === 6) {
      multiplier = 0.9;
    } else {
      multiplier = duration / 7;
    }
    return multiplier;
  }
}
