import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPrefix } from '@fortawesome/fontawesome-common-types';

import { selectHoliday } from '../../redux/actions/actions';


import CustomerDetailsForm from '../../components/customer-details-form/customer-details-form';
import Overlay from '../../components/overlay/overlay';
import PaymentForm from '../../components/payment-form/payment-form';
import PaymentOptionsForm from '../../components/payment-options-form/payment-options-form';
import BookablePeriod from '../../components/bookable-period/bookable-period';
import { ApiService } from '../../services/api.service';
import { PaymentOptionsUtils } from '../../utilities/payment-options';

import { saveCompletedBooking } from '../../redux/actions/actions';

import { Constants } from '../../utilities/constants';

import { Day } from '../../models/day';
import { Period } from '../../models/period';

import { createDays } from '../../components/booking-calendar/calendar-utilities';

import './create-booking.scss';

const CreateBooking = (): ReactElement<string, string> => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [days, setDays] = useState<Map<string, Day>>(null);
  const [bookings, setBookings] = useState([]);
  const selectedPeriod: Period = useSelector((state: any) => state.selectedHoliday);
  const [currentPage, setCurrentPage] = useState(0);
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [paymentDesciption, setPaymentDesciption] = useState('');
  const [paymentOption, setPaymentOption] = useState('fullAmount');
  const [customerDetails, setCustomerDetails] = useState({
    firstName: '',
    lastName: '',
    nameOrNumber: '',
    street: '',
    townOrCity: '',
    postcode: '',
    email: '',
    telephone: ''
  });
  const [completedBooking, setCompletedBooking] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [isLoadingBooking, setIsLoadingBooking] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadBookings = async () => {
      const data = await ApiService.listGoogleCalendarEvents();
      const json = await data.json();
      setBookings(json);
    };

    loadBookings()
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (id) {
      setIsLoadingBooking(true);
    }

    if (!days) {
      setDays(createDays());
    }
  }, []);

  useEffect(() => {
    if (!days) {
      setDays(createDays());
    }
  }, [days]);

  useEffect(() => {
    if (bookings && days) {
      bookings.forEach((booking: any) => {
        const bookingStart = days.get(moment(booking.start.date, Constants.CALENDAR_DATE_PICKER_FORMAT).format(Constants.CALENDAR_KEY_FORMAT));
        const bookingEnd = days.get(moment(booking.end.date, Constants.CALENDAR_DATE_PICKER_FORMAT).format(Constants.CALENDAR_KEY_FORMAT));

        if (bookingStart && bookingEnd) {
          let day = bookingStart;
          while (day.key < bookingEnd.key) {
            day.isAvailable = false;
            day = day.next;
          }
          bookingEnd.isChangeover = true;
        }
      });

      // props.setCalendar(days);

      // setIsLoading(false);
    }

  }, [bookings, days]);

  useEffect(() => {
    if (!selectedPeriod) {
      if (id) {
        if (days) {
          ApiService.getQuotation(id).then((result) => {
            if (result.status === 404) {
              navigate(Constants.Routes.BOOKING_NOT_FOUND);
            } else {
              result.json().then((quote: any) => {
                switch (quote.quotationStatus) {
                  case 'AWAITING_APPROVAL':
                    navigate(Constants.Routes.AWAITING_APPROVAL);
                    break;
                  case 'REJECTED':
                    navigate(Constants.Routes.BOOKING_REJECTED);
                    break;
                }

                const startDay = days.get(quote.arrivalDate);
                const endDay = days.get(quote.departureDate);

                const period: Period = new Period();
                period.initialise(startDay, endDay);

                period.rate = quote.rate;

                if (!period.isAvailable()) {
                  navigate(Constants.Routes.BOOKING_NOT_AVAILABLE);
                }

                dispatch(selectHoliday(period));

                setIsLoadingBooking(false);
              });
            }
          });
        }
      } else {
        navigate(Constants.Routes.BOOKINGS);
      }

    } else {
      const paymentOptions = new PaymentOptionsUtils().getPaymentOptions(selectedPeriod);
      setPaymentOptions(paymentOptions);
    }
  }, [days, selectedPeriod]);

  useEffect(() => {
    if (selectedPeriod && paymentOption) {
      const description = `Cleveland Holiday Cottage - ${selectedPeriod.days.length} nights from ${selectedPeriod.startDay.formattedDate}`;
      const depositOrFull = `${paymentOption === 'deposit' ? 'Deposit' : 'Full payment'}`;

      setPaymentDesciption(`${description}. ${depositOrFull}.`);
    }
  }, [selectedPeriod, paymentOption]);

  useEffect(() => {
    if (completedBooking) {

      ApiService.createBooking(completedBooking)
        .then((result) => {
          if (result.status !== 200) {
            navigate(Constants.Routes.GENERAL_ERROR);
          } else {
            result.json().then((data: any) => {
              completedBooking.bookingReference = data.bookingReference;

              dispatch(saveCompletedBooking(completedBooking));

              navigate(Constants.Routes.BOOKING_COMPLETE);
            });
          }
        }).catch(error => {
          console.error(error);
          navigate(Constants.Routes.GENERAL_ERROR);
        });
    }

  }, [completedBooking]);

  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);

    scrollToTop();
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    scrollToTop();
  };

  const handlePaymentComplete = async () => {
    setCompletedBooking(createBookingDetails());
  };

  const handlePaymentOptionSelected = (paymentOption: any) => {
    setPaymentOption(paymentOption);
  };

  const customerDetailsEntered = (customerDetails: any) => {
    setCustomerDetails(customerDetails);
  };

  const handleStartProcessing = () => {
    setProcessing(true);
  };

  const handleStopProcessing = () => {
    setProcessing(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const createBookingDetails = (): any => {
    const payInFull = paymentOption === 'fullAmount';

    return {
      description:
        'Cleveland Holiday Cottage, Llangennith. ' +
        selectedPeriod.days.length + ' nights from ' +
        moment(selectedPeriod.startDay.key, Constants.CALENDAR_KEY_FORMAT).format('DD/MM/YYYY') +
        ' - ' +
        customerDetails.email.trim(),
      booking: {
        arrivalDate: selectedPeriod.startDay.key,
        departureDate: selectedPeriod.endDay.key,
        duration: selectedPeriod.days.length,
        rate: selectedPeriod.rate.toFixed(2)
      },
      customer: {
        firstName: customerDetails.firstName.trim(),
        lastName: customerDetails.lastName.trim(),
        houseNumber: customerDetails.nameOrNumber.trim(),
        street: customerDetails.street.trim(),
        town: customerDetails.townOrCity.trim(),
        postcode: customerDetails.postcode.trim(),
        email: customerDetails.email.trim(),
        telephone: customerDetails.telephone.trim()
      },
      payment: {
        initialPayment: {
          payInFull,
          paymentDate: moment().format(),
          amountPaid: payInFull ? selectedPeriod.rate.toFixed(2) : getDeposit(selectedPeriod.rate).toFixed(2),
          amountRemaining: payInFull ? 0 : getRemaingBalance(selectedPeriod.rate).toFixed(2),
          balanceDueDate: payInFull ? undefined : getBalanceDueDate(selectedPeriod.startDay).format(Constants.CALENDAR_KEY_FORMAT)
        }
      }
    };
  };

  // The number of weeks before a booking starts that allows the customer to pay 1/3 deposit rather than the full amount
  const DEPOSIT_CUT_OFF_WEEKS = 6;

  // The amount of deposit that is taken
  const DEPOSIT_RATIO = 0.3;

  const calculateDeposit = (rate: number) => {
    return rate * DEPOSIT_RATIO;
  };

  const getDeposit = (rate: any) => {
    return calculateDeposit(rate);
  };

  const getRemaingBalance = (rate: any) => {
    return rate - getDeposit(rate);
  };

  const getBalanceDueDate = (arrivalDay: Day) => {
    return calculateBalanceRequiredDate(arrivalDay.key);
  };

  const calculateBalanceRequiredDate = (startDateKey: string) => {
    const arrivalDate = moment(startDateKey, Constants.CALENDAR_KEY_FORMAT);
    arrivalDate.subtract(DEPOSIT_CUT_OFF_WEEKS, 'weeks');
    return arrivalDate;
  };

  return (
    <>
      {processing &&
        <Overlay
          innerHtml={
            <>
              <div className="--align-center">
                <h1 >Processing payment - please wait</h1>
                <p>
                  <FontAwesomeIcon
                    icon={['fas' as IconPrefix, 'cog']}
                    size="4x"
                    className="spinner" />
                </p>
              </div>
            </>}
        />
      }

      {isLoadingBooking &&
        <Overlay
          innerHtml={
            <>
              <div className="--align-center">
                <h1 >Loading booking - please wait</h1>
                <p>
                  <FontAwesomeIcon
                    icon={['fas' as IconPrefix, 'cog']}
                    size="4x"
                    className="spinner" />
                </p>
              </div>
            </>}
        />
      }

      <div className="content --padding-md">
        <h1>SECURE CHECKOUT</h1>

        {
          !isLoadingBooking && <>
            <h2>YOUR HOLIDAY</h2>

            {selectedPeriod &&
              <BookablePeriod
                arrivalDate={selectedPeriod.startDay.formattedDate}
                departureDate={selectedPeriod.endDay.formattedDate}
                duration={selectedPeriod.days.length}
                discount={selectedPeriod.startDay.discount}
                rate={selectedPeriod.rate}
                previousRate={selectedPeriod.previousRate}
              />
            }

            <p className="--align-right --margin-top-lg">
              <a href="/bookings">Select a different holiday</a>
            </p>

            <div>

              {currentPage === 0 &&
                <>
                  <h2>PAYMENT OPTIONS</h2>
                  <h3>(STEP 1 OF 3)</h3>
                  <PaymentOptionsForm
                    period={selectedPeriod}
                    paymentOption={paymentOption}
                    paymentOptions={paymentOptions}
                    onPaymentOptionSelected={handlePaymentOptionSelected}
                    onNext={handleNext}
                  />
                </>
              }

              {currentPage === 1 &&
                <>
                  <h2>YOUR DETAILS</h2>
                  <h3>(STEP 2 OF 3)</h3>
                  <CustomerDetailsForm
                    customerDetails={customerDetails}
                    customerDetailsEntered={customerDetailsEntered}
                    onPrevious={handlePrevious}
                    onNext={handleNext}
                  />
                </>
              }


              {currentPage === 2 &&
                <>
                  <h2>PAYMENT</h2>
                  <h3>(STEP 3 OF 3)</h3>
                  <PaymentForm
                    amount={paymentOption === 'deposit' ? paymentOptions.deposit.amount : paymentOptions.fullAmount}
                    paymentDesciption={paymentDesciption}
                    onPrevious={handlePrevious}
                    onPaymentComplete={handlePaymentComplete}
                    onStartProcessing={handleStartProcessing}
                    onStopProcessing={handleStopProcessing}
                  />
                </>
              }
            </div >

            <p className="book-with-confidence --align-center">
              <span className="--margin-right-md --bump-down"><FontAwesomeIcon icon={['fas' as IconPrefix, 'lock']} size="2x" /></span>
              Book with confidence - we use encryption to protect your data</p>
          </>
        }
      </div >
    </>
  );
};

export default CreateBooking;
