import { ReactElement } from 'react';


import { Day } from '../../models/day';
import './booking-calendar.scss';

type BookingCalendarRowProps = {
  week: Day[];
  selectPeriod: (day: Day) => void;
}

const BookingCalendarRow = (props: BookingCalendarRowProps): ReactElement<string, string> => {
  const getClass = (day: Day) => {
    let cssClass = '';
    if (!day.isAvailable) {
      // Set to greyed out
      cssClass += ' not-available-day';
    } else if (day.isSelected) {
      // Set selected style
      cssClass += ' selected-day';

      // TODO selected period
      // const selectedPeriod = this.calendarDataService.getSelectedPeriod();

      // if (day === selectedPeriod.startDay) {
      //   cssClass += ' start-of-period';
      // } else if (day === selectedPeriod.endDay.previous) {
      //   cssClass += ' end-of-period';
      // }
    } else if (day.isChangeover && day.next && day.next.isAvailable && !day.next.isChangeover) {
      cssClass += ' changeover changeoverFont';

    } else {
      cssClass += ' unselected-day';
    }

    // TODO colour days outside of current month differently
    // const currentMonth = props.selectedMonth;

    // if (day.monthNumber !== currentMonth) {
    //   cssClass += ' outside-of-month-day';
    // }

    return cssClass;
  };

  return (
    <div>
      {props.week.map((day: Day, i: number) =>
        <div
          className={"calendar-day " + getClass(day)}
          onClick={props.selectPeriod.bind(this, day)}
          key={i}>{day.dayNumber}</div>
      )}
    </div>
  );

};

export default BookingCalendarRow;
