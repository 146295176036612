import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import moment from 'moment';

import InfoBanner from '../../components/info-banner/info-banner';

import './booking-complete.scss';
import { Constants } from '../../utilities/constants';

const BookingComplete = (): ReactElement<string, string> => {
  const navigate = useNavigate();
  const completedBooking: any = useSelector((state: any) => state.completedBooking);

  useEffect(() => {
    if (!completedBooking) {
      navigate(Constants.Routes.HOME);
    }

  }, [completedBooking]);

  return (
    <>
      {
        completedBooking && <div className="content --padding-md">
          <InfoBanner
            innerHtml={
              <>
                <h1>Booking complete</h1>
                <h2 className="--align-left --margin-top-lg">Thank you for your booking!</h2>
                <h3 className="--align-left --margin-top-lg">Here are your booking details:</h3>
                <div className="--margin-top-lg">
                  <table className="--align-left">
                    <tbody>
                      <tr><td className="--padding-right-lg">Booking reference:</td><td>{completedBooking.bookingReference}</td></tr>
                      <tr><td className="--padding-right-lg">Arrival:</td><td>{moment(completedBooking.booking.arrivalDate, Constants.CALENDAR_KEY_FORMAT).format(Constants.CALENDAR_LONG_DATE_FORMAT)}</td></tr>
                      <tr><td className="--padding-right-lg">Departure:</td><td>{moment(completedBooking.booking.departureDate, Constants.CALENDAR_KEY_FORMAT).format(Constants.CALENDAR_LONG_DATE_FORMAT)}</td></tr>
                      <tr><td className="--padding-right-lg">Duration:</td><td> {completedBooking.booking.duration} nights</td></tr>
                    </tbody>
                  </table>
                </div>
                <p className="--align-left --margin-top-lg">We will send these booking details to the email address that you supplied ({completedBooking.customer.email})</p>

              </>}
          />
          <p className="--margin-top-xl --margin-left-lg"><a href="/">Return to the home page</a></p>
        </div>
      }
    </>
  );
};

export default BookingComplete;
