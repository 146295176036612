import { ReactElement } from 'react';

import './overlay.scss';

type OverlayProps = {
  innerHtml: any,
  isError?: boolean
}
const Overlay = (props: OverlayProps): ReactElement<string, string> => {
  return (
    <div className={"overlay " + (props.isError ? 'overlay__error' : 'overlay__success')} >
      {props.innerHtml}
    </div>
  );
};

export default Overlay;
