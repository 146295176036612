import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Constants } from '../../utilities/constants';

import './sliding-menu.scss';

const SlidingMenu = (props: any): ReactElement<string, string> => {
  return (
    <>
      {
        props.navOpen && <div className="sliding-menu">
          <ul>
            <li onClick={props.toggleMenu}><Link to={Constants.Routes.HOME}>Home</Link></li>
            <li onClick={props.toggleMenu}><Link to={Constants.Routes.BOOKINGS}>Bookings</Link></li>
            <li onClick={props.toggleMenu}><Link to={Constants.Routes.ABOUT}>About</Link></li>
            <li onClick={props.toggleMenu}><Link to={Constants.Routes.GALLERY}>Gallery</Link></li>
            <li onClick={props.toggleMenu}><Link to={Constants.Routes.CONTACT}>Contact</Link></li>
            <li onClick={props.toggleMenu}><Link to={Constants.Routes.HOW_TO_FIND_US}>How to find us</Link></li>
          </ul>
        </div >
      }
    </>
  );
};

export default SlidingMenu;
