import { ReactElement } from 'react';

const AwaitingApproval = (): ReactElement<string, string> => {
  return (
    <div className="content --padding-md">
      <h1>Booking Awaiting Approval</h1>
      <p>We are still considering your booking request - please try again later.</p>
      <p><a href="/">Go to the home page</a></p>
    </div>
  );
};

export default AwaitingApproval;
