import { Constants } from '../utilities/constants';
import { Day } from './day';

export class Period {
  public startDay: Day;
  public endDay: Day;
  public days: Array<Day>;
  public rate = 0;
  public previousRate = 0;

  initialise(startDay: Day, endDay: Day): void {
    this.startDay = startDay;
    this.endDay = endDay;

    this.days = [];

    let day: Day = startDay;
    while (day !== endDay) {
      this.days.push(day);
      day = day.next;
    }
  }

  clearSelection(): void {
    this.toggleSelection(false);
  }

  toggleSelection(isSelected?: boolean): void {
    const day: Day = this.startDay;

    if (!arguments.length) {
      isSelected = !day.isSelected;
    }

    this.days?.forEach((day) => {
      day.isSelected = isSelected;
    });
    // while (day && day !== this.endDay) {
    //   day.isSelected = isSelected;
    //   day = day.next;
    // }
  }

  isAvailable(): boolean {
    let isAvailable = false;

    let day = this.startDay;

    if (day) {
      isAvailable = day.isAvailable;

      while (day !== this.endDay && isAvailable) {
        isAvailable = isAvailable && day.isAvailable;
        day = day.next;
      }
    }

    return isAvailable;
  }

  calculateRates(): void {
    let currentRate: number = null;
    let previousRate: number = null;

    const day = this.startDay;
    if (day.isWithinWeeks(2)) {
      day.discount = 0.1;
    }

    // Get the highest rate
    if (this.days) {
      const rates = this.days.map((item) => item.weeklyRate);

      if (rates && rates.length){
        const highestRate = rates.reduce((acc, value) => (value > acc) ? value : acc);
        let periodRate = Math.round(highestRate * Constants.getMultiplier(this.days.length));

        // Discounted rate calculations
        if (this.days.length >= 2 && this.days[0].discount) {
          previousRate = periodRate;
          periodRate = periodRate * (1 - day.discount);
        }

        currentRate = Math.round(periodRate * 100) / 100;

        this.rate = currentRate;
        this.previousRate = previousRate;
      }
    }
  }
}
