import moment from 'moment';
import { Constants } from '../utilities/constants';

export class Day {
  public key: string;
  public dayNumber: string;
  public monthNumber: number;
  public formattedDate: string;
  public isSelected: boolean;
  public isChangeover: boolean;
  public isAvailable: boolean;
  public next?: Day;
  public previous?: Day;
  public weeklyRate?: number;
  public discount?: number;

  constructor(key: string, isSelected = false, isChangeover = false, isAvailable =true) {
    this.key = key;

    const day = moment(key, Constants.CALENDAR_KEY_FORMAT);
    this.dayNumber = day.format('D');
    this.monthNumber = Number.parseInt(day.format('MM')) - 1;
    this.formattedDate =  day.format(Constants.CALENDAR_LONG_DATE_FORMAT);

    this.isSelected = isSelected;
    this.isChangeover = isChangeover;
    this.isAvailable = isAvailable;
  }

  isWithinWeeks(nuumberOfWeeks: number): boolean {
    const cutOff = moment().add(nuumberOfWeeks, 'weeks');
    const day = moment(this.key, Constants.CALENDAR_KEY_FORMAT);
    return (day.isBefore(cutOff));
  }
}
