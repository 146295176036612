import moment from 'moment';

import { Constants } from './constants';
import { Period } from '../models/period';

// The number of weeks before a booking starts that allows the customer to pay 1/3 deposit rather than the full amount
const DEPOSIT_CUT_OFF_WEEKS = 6;

// The amount of deposit that is taken
const DEPOSIT_RATIO = 0.3;

export class PaymentOptionsUtils {
  calculateDeposit(rate: number) {
    return rate * DEPOSIT_RATIO;
  }

  isOutsideFullBalancePeriod(startDateKey: string) {
    const currentDate = moment();
    const arrivalDate = moment(startDateKey, Constants.CALENDAR_KEY_FORMAT);

    const duration = arrivalDate.diff(currentDate, 'weeks');

    return duration >= DEPOSIT_CUT_OFF_WEEKS;
  }

  calculateBalanceRequiredDate(startDateKey: string) {
    const arrivalDate = moment(startDateKey, Constants.CALENDAR_KEY_FORMAT);
    arrivalDate.subtract(DEPOSIT_CUT_OFF_WEEKS, 'weeks');
    return arrivalDate;
  }

  getPaymentOptions(period: Period) {
    // TODO payment options object?
    const paymentOptions: any = {};

    if (period) {
      paymentOptions.fullAmount = period.rate;

      if (this.isOutsideFullBalancePeriod(period.startDay.key)) {
        const balanceRequiredDate = this.calculateBalanceRequiredDate(period.startDay.key);

        const depositAmount = this.calculateDeposit(period.rate);
        paymentOptions.deposit = {
          amount: depositAmount,
          balanceRequired: {
            amount: period.rate - depositAmount,
            key: balanceRequiredDate.format(Constants.CALENDAR_KEY_FORMAT),
            display: balanceRequiredDate.format(Constants.CALENDAR_LONG_DATE_FORMAT)
          }
        };
      }
    }
    return paymentOptions;
  }
}
