import { ReactElement } from 'react';

const BookingNotFound = (): ReactElement<string, string> => {
  return (
    <div className="content --padding-md">
      <h1>Booking not found</h1>
      <p className='--margin-bottom-xl'>Sorry but we could not find the booking. Please give us a ring or drop us an email so that we can assist you further.</p>
      <p><a href="/">Return to the home page</a></p>
    </div>
  );
};

export default BookingNotFound;
