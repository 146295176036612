import { ReactElement } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import './about-icon.scss';

type AboutIconProps = {
  faLibrary: string,
  icon: IconName,
  description: string
}

const AboutIcon = (props: AboutIconProps): ReactElement<string, string> => {
  return (
    <>
      <div className="about-icon">
        <div className="about-icon__image">
          <FontAwesomeIcon icon={[props.faLibrary as IconPrefix, props.icon]} size="4x" />
        </div>
        <div className="about-icon__text --margin-top-md">{props.description}</div>
      </div>
    </>
  );
};

export default AboutIcon;
