import React from 'react';
import { ReactElement, useState } from 'react';

import Textbox from '../form-elements/textbox/textbox';
import InfoBanner from '../info-banner/info-banner';

import './approve-booking-form.scss';

type ApproveBookingFormProps = {
  isSending: boolean,
  approvalError: string,
  approveBooking: (approval: any, isApproved: boolean) => void;
}

type ErrorInfo = {
  passwordError?: string
};

type ApproveBookingFormState = {
  password: string,
  passwordError: string,
}

const ApproveBookingForm = (props: ApproveBookingFormProps): ReactElement<string, string> => {
  const [approval, setApproval] = useState<ApproveBookingFormState>({
    password: '',
    passwordError: ''
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setApproval({
      ...approval,
      [event.target.name]: value,
      [`${event.target.name}Error`]: ''
    });
  };

  const handleValidation = () => {
    const errors: ErrorInfo = {};

    if (!approval.password) {
      errors.passwordError = 'Password cannot be empty';
    }

    return errors;
  };

  const onApprove = (event: any): void => {
    const errors = handleValidation();

    if (Object.keys(errors).length) {
      setApproval({
        ...approval,
        ...errors
      });
    } else {
      props.approveBooking(approval, true);
    }
    event.preventDefault();
  };

  const onReject = (event: any): void => {
    const errors = handleValidation();

    if (Object.keys(errors).length) {
      setApproval({
        ...approval,
        ...errors
      });
    } else {
      props.approveBooking(approval, false);
    }
    event.preventDefault();
  };

  return (
    <div className="contact-form --padding-md">
      <>
        <form>

          <div className="--form-group">
            <Textbox
              label="Admin password"
              id="password"
              name="password"
              handleChange={handleChange}
              value={approval.password}
              error={approval.passwordError}
            />
          </div>

          {!props.isSending && <div className={"--margin-top-md --align-right"}>
            <button
              className="button button--secondary --margin-right-md"
              onClick={onReject}>Reject booking
            </button>
            <button
              className="button button--primary"
              onClick={onApprove}>Approve booking
            </button>
          </div>}

          {props.isSending &&
            <div className='--align-right'>Sending approval ...</div>
          }
        </form>


        {props.approvalError &&
          <InfoBanner
            isError={props.approvalError && props.approvalError.length > 0}
            innerHtml={
              <>
                <p>Sorry - there was an error sending your approval</p>
                <p>{props.approvalError}</p>
              </>}
          />
        }
      </>
    </div >
  );
};

export default ApproveBookingForm;
