import { ReactElement, useEffect, useState } from 'react';

import Textbox from '../form-elements/textbox/textbox';

import CustomerDetails from '../../types/customer-details';
import './customer-details-form.scss';

const CustomerDetailsForm = (props: any): ReactElement<string, string> => {
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>({
    firstName: props.customerDetails.firstName || '',
    firstNameError: '',
    lastName: props.customerDetails.lastName || '',
    lastNameError: '',
    nameOrNumber: props.customerDetails.nameOrNumber || '',
    nameOrNumberError: '',
    street: props.customerDetails.street || '',
    streetError: '',
    townOrCity: props.customerDetails.townOrCity || '',
    townOrCityError: '',
    postcode: props.customerDetails.postcode || '',
    postcodeError: '',
    email: props.customerDetails.email || '',
    emailError: '',
    confirmEmail: props.customerDetails.confirmEmail || '',
    confirmEmailError: '',
    telephone: props.customerDetails.telephone || '',
    telephoneError: '',
    termsAndConditions: props.customerDetails.termsAndConditions || '',
    termsAndConditionsError: '',
    onNext: props.onNext,
    onPrevious: () => props.onPrevious
  });

  useEffect(() => {
    props.customerDetailsEntered(customerDetails);
  }, [customerDetails]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setCustomerDetails({
      ...customerDetails,
      [event.target.name]: value,
      [`${event.target.name}Error`]: ''
    });
  };

  const handleValidation = () => {
    type ErrorInfo = {
      firstNameError?: string,
      lastNameError?: string,
      nameOrNumberError?: string,
      streetError?: string,
      townOrCityError?: string,
      postcodeError?: string,
      emailError?: string,
      confirmEmailError?: string,
      telephoneError?: string,
    };
    const errors: ErrorInfo = {};

    // TODO validate email
    const validateEmail = (email: string) => {
      /* eslint-disable max-len */
      // const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // return email.length === 0 || regExp.test(String(email).toLowerCase());
      return true;
    };

    if (!customerDetails.firstName) {
      errors.firstNameError = 'First name cannot be empty';
    }
    if (!customerDetails.lastName) {
      errors.lastNameError = 'Last name cannot be empty';
    }
    if (!customerDetails.nameOrNumber) {
      errors.nameOrNumberError = 'Building name or number cannot be empty';
    }
    if (!customerDetails.street) {
      errors.streetError = 'Street cannot be empty';
    }
    if (!customerDetails.townOrCity) {
      errors.townOrCityError = 'Town or City cannot be empty';
    }
    if (!customerDetails.postcode) {
      errors.postcodeError = 'Postcode cannot be empty';
    }
    if (!customerDetails.email) {
      errors.emailError = 'Email cannot be empty';
    }
    if (!validateEmail(customerDetails.email)) {
      errors.emailError = 'Email is invalid';
    }
    if (!customerDetails.confirmEmail) {
      errors.confirmEmailError = 'Confirmed email cannot be empty';
    }
    if (!validateEmail(customerDetails.confirmEmail)) {
      errors.emailError = 'Email is invalid';
    }
    if (customerDetails.email !== customerDetails.confirmEmail) {
      errors.confirmEmailError = 'Emails must match';
    }
    if (!customerDetails.telephone) {
      errors.telephoneError = 'Telephone number cannot be empty';
    }

    return errors;
  };

  const onPrevious = () => {
    props.onPrevious();
  };

  const onNext = () => {
    const errors = handleValidation();

    if (Object.keys(errors).length) {
      setCustomerDetails({
        ...customerDetails,
        ...errors
      });
    } else {
      props.onNext();
    }
  };

  return (
    <>
      <form>
        <div className="--form-group">
          <Textbox
            label="First name"
            id="firstName"
            name="firstName"
            handleChange={handleChange}
            value={customerDetails.firstName}
            error={customerDetails.firstNameError}
          />
        </div>

        <div className="--form-group">
          <Textbox
            label="Last name"
            id="lastName"
            name="lastName"
            handleChange={handleChange}
            value={customerDetails.lastName}
            error={customerDetails.lastNameError}
          />
        </div>

        <div className="--form-group">
          <Textbox
            label="Building name or number"
            id="nameOrNumber"
            name="nameOrNumber"
            handleChange={handleChange}
            value={customerDetails.nameOrNumber}
            error={customerDetails.nameOrNumberError}
          />
        </div>

        <div className="--form-group">
          <Textbox
            label="Street"
            id="street"
            name="street"
            handleChange={handleChange}
            value={customerDetails.street}
            error={customerDetails.streetError}
          />
        </div>

        <div className="--form-group">
          <Textbox
            label="Town or City"
            id="townOrCity"
            name="townOrCity"
            handleChange={handleChange}
            value={customerDetails.townOrCity}
            error={customerDetails.townOrCityError}
          />
        </div>

        <div className="--form-group">
          <Textbox
            label="Postcode"
            id="postcode"
            name="postcode"
            handleChange={handleChange}
            value={customerDetails.postcode}
            error={customerDetails.postcodeError}
          />
        </div>

        <div className="--form-group">
          <Textbox
            label="Email"
            id="email"
            name="email"
            handleChange={handleChange}
            value={customerDetails.email}
            error={customerDetails.emailError}
          />
        </div>

        <div className="--form-group">
          <Textbox
            label="Confirm email"
            id="confirmEmail"
            name="confirmEmail"
            handleChange={handleChange}
            value={customerDetails.confirmEmail}
            error={customerDetails.confirmEmailError}
          />
        </div>

        <div className="--form-group">
          <Textbox
            label="Telephone number"
            id="telephone"
            name="telephone"
            handleChange={handleChange}
            value={customerDetails.telephone}
            error={customerDetails.telephoneError}
          />
        </div>

        {/*
      <div>
        <label id="termsAndConditionsLabel" htmlFor="termsAndConditions">Terms and conditions:</label>
        <input
          type="checkbox"
          id="termsAndConditions"
          name="termsAndConditions"
          onChange={handleInputChange}
          value={customerDetails.termsAndConditions}
        />
      </div>
      */}

        <div className="--margin-top-lg --align-right">
          <button
            type="button"
            className={"button button--secondary --margin-right-md"}
            onClick={onPrevious}>Previous step</button>

          <button
            type="button"
            className={"button button--primary"}
            onClick={onNext}>Next step</button>

        </div>
      </form>
    </>
  );
};

export default CustomerDetailsForm;
