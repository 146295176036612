import React from 'react';
import { ReactElement, useState } from 'react';

import Textarea from '../form-elements/textarea/textarea';
import Textbox from '../form-elements/textbox/textbox';
import ContactDetail from '../../types/contact-detail';
import InfoBanner from '../info-banner/info-banner';

import { Constants } from '../../utilities/constants';

import './contact-form.scss';

type ContactFormProps = {
  sendMessage: (contactDetail: ContactDetail) => void;
  isSending: boolean,
  isSent: boolean,
  sendingError: boolean
}

type ContactFormState = {
  name: string,
  email: string,
  telephone: string,
  message: string,
  nameError: string,
  emailError: string,
  messageError: string
}

const ContactForm = (props: ContactFormProps): ReactElement<string, string> => {
  const [contact, setContact] = useState<ContactFormState>({
    name: '',
    email: '',
    telephone: '',
    message: '',
    nameError: '',
    emailError: '',
    messageError: ''
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setContact({
      ...contact,
      [event.target.name]: value,
      [`${event.target.name}Error`]: ''
    });
  };

  const handleValidation = () => {
    type ErrorInfo = {
      nameError?: string,
      emailError?: string,
      messageError?: string,
    };
    const errors: ErrorInfo = {};

    // validateEmail(email: string) {
    //   /* eslint-disable max-len */
    //   const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   return email.length === 0 || regExp.test(String(email).toLowerCase());
    // }

    if (!contact.name) {
      errors.nameError = 'Name cannot be empty';
    }
    if (!contact.email) {
      errors.emailError = 'Email cannot be empty';
    }
    if (!contact.message) {
      errors.messageError = 'Message cannot be empty';
    }

    return errors;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    const errors = handleValidation();

    if (Object.keys(errors).length) {
      setContact({
        ...contact,
        ...errors
      });
    } else {
      props.sendMessage({
        name: contact.name,
        email: contact.email,
        telephone: contact.telephone,
        message: contact.message
      });
    }
    event.preventDefault();
  };

  return (
    <div className="contact-form --padding-md">
      <>
        {!props.isSent &&
          <form onSubmit={handleSubmit}>

            <div className="--form-group">
              <Textbox
                label="Name"
                id="name"
                name="name"
                handleChange={handleChange}
                value={contact.name}
                error={contact.nameError}
              />
            </div>

            <div className="--form-group">
              <Textbox
                label="Email"
                id="email"
                name="email"
                handleChange={handleChange}
                value={contact.email}
                error={contact.emailError}
              />
            </div>

            <div className="--form-group">
              <Textbox
                label="Telephone number"
                id="telephone"
                name="telephone"
                handleChange={handleChange}
                value={contact.telephone}
                optional={true}
              />
            </div>

            <div className="--form-group">
              <Textarea
                label="Message"
                id="message"
                name="message"
                handleChange={handleChange}
                value={contact.message}
                rows={5}
                error={contact.messageError}
              />
            </div>

            <div className={"--margin-top-md --align-right"}>
              {!props.isSending && <button className="button button--primary">Send message</button>}
              {props.isSending && <div>Sending message...</div>}
            </div>
          </form>
        }

        {props.isSent &&
          <InfoBanner
            isError={props.sendingError}
            innerHtml={
              <>
                <p>Message sent</p>
                <p>Thank you for your message. We will be in touch as soon as possible.</p>
              </>}
          />
        }

        {props.sendingError &&
          <InfoBanner
            isError={props.sendingError}
            innerHtml={
              <>
                <p>Sorry - there was an error sending your message.</p>
                <p>Please try again or <a href={`mailto:${Constants.CLEVELAND_GOWER_EMAIL}`}>send us an email</a> instead.</p>
              </>}
          />
        }
      </>
    </div >
  );
};

export default ContactForm;
