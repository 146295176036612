import { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPrefix } from '@fortawesome/fontawesome-common-types';

import './info-banner.scss';

type InfoBannerProps = {
  innerHtml: any,
  isError?: boolean
}
const InfoBanner = (props: InfoBannerProps): ReactElement<string, string> => {
  return (
    <div className={"info-banner " + (props.isError ? 'info-banner__error' : 'info-banner__success')} >
      <p><FontAwesomeIcon icon={['fas' as IconPrefix, props.isError ? 'triangle-exclamation' : 'circle-check']} size="4x" /></p>
      <div>
        {props.innerHtml}
      </div>
    </div >
  );
};

export default InfoBanner;
