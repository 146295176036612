import { ReactElement } from 'react';

const BookingRejected = (): ReactElement<string, string> => {
  return (
    <div className="content --padding-md">
      <h1>Booking Rejected</h1>
      <p>Sorry but we have not been able to approve your booking on this occasion.</p>
      <p><a href="/">Go to the home page</a></p>
    </div>
  );
};

export default BookingRejected;
