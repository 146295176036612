import { ReactElement } from 'react';
import NumberFormat from 'react-number-format';
import BookablePeriodProps from '../../types/bookable-period';

import './bookable-period.scss';

const BookablePeriod = (props: BookablePeriodProps): ReactElement<string, string> => {
  return (
    <>
      <div className="bookable-period">

        {props.arrivalDate ?
          <div>
            <div className="bookable-period__details">
              <div>
                <div className="bookable-period__dates">Arrive: {props.arrivalDate}</div>
                <div className="bookable-period__dates">Depart: {props.departureDate}</div>
                <div className="bookable-period__duration">{props.duration} nights</div>
              </div>

              {props.discount &&
                <div className="bookable-period__rate --margin-top-md">*** Special offer: {props.discount * 100}% off ! ***</div>
              }

              <div className="bookable-period__rate --margin-top-md">
                <NumberFormat
                  value={props.rate}
                  thousandSeparator={true}
                  displayType={'text'}
                  prefix={'£'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
                {props.discount &&
                  <span
                    className="bookable-period__previous-rate"> (was&nbsp;
                    <NumberFormat
                      value={props.previousRate}
                      thousandSeparator={true}
                      displayType={'text'}
                      prefix={'£'}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />)
                  </span>}
              </div>
            </div>
          </div>
          :
          <div className="bookable-period__unselected --padding-y-lg">
            <h3>No dates selected</h3>
            <p>Click on dates in the calendar above to check price and availability</p>
          </div>
        }
      </div>
    </>
  );
};

export default BookablePeriod;
