import { ReactElement } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { library } from '@fortawesome/fontawesome-svg-core';

import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import About from './pages/about/about';
import ApproveBooking from './pages/approve-booking/approve-booking';
import AwaitingApproval from './pages/errors/awaiting-approval';
import BookingComplete from './pages/booking-complete/booking-complete';
import BookingNotAvailable from './pages/errors/booking-not-available';
import BookingNotFound from './pages/errors/booking-not-found';
import BookingRejected from './pages/errors/booking-rejected';
import Bookings from './pages/bookings/bookings';
import Contact from './pages/contact/contact';
import CreateBooking from './pages/create-booking/create-booking';
import Gallery from './pages/gallery/gallery';
import GeneralError from './pages/errors/general-error';
import GuestInformation from './pages/guest-information/guest-information';
import Home from './pages/home/home';
import HowToFindUs from './pages/how-to-find-us/how-to-find-us';
import PageNotFound from './pages/errors/page-not-found';
import PayBalance from './pages/pay-balance/pay-balance';
import ReportCurrentBookings from './pages/admin/reports/current-bookings';
import ReportYearlyOccupancy from './pages/admin/reports/yearly-occupancy';

import NavigationBar from './components/navigation-bar/navigation-bar';
import Footer from './components/footer/footer';

import { Constants } from './utilities/constants';

import './App.scss';

library.add(fab, far, fas);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const App = (): ReactElement<string, string> => {
  return (
    <>
      <Elements stripe={stripePromise}>
        <Router>
          <div>
            <NavigationBar />

            <Routes>
              <Route path={Constants.Routes.ABOUT} element={<About />} />
              <Route path={Constants.Routes.APPROVE_BOOKING} element={<ApproveBooking />} />
              <Route path={Constants.Routes.AWAITING_APPROVAL} element={<AwaitingApproval />} />
              <Route path={Constants.Routes.BOOKING_COMPLETE} element={<BookingComplete />} />
              <Route path={Constants.Routes.BOOKING_NOT_AVAILABLE} element={<BookingNotAvailable />} />
              <Route path={Constants.Routes.BOOKING_NOT_FOUND} element={<BookingNotFound />} />
              <Route path={Constants.Routes.BOOKING_REJECTED} element={<BookingRejected />} />
              <Route path={Constants.Routes.BOOKINGS} element={<Bookings />} />
              <Route path={Constants.Routes.CUSTOM_BOOKING} element={<CreateBooking />} />
              <Route path={Constants.Routes.CONTACT} element={<Contact />} />
              <Route path={Constants.Routes.CREATE_BOOKING} element={<CreateBooking />} />
              <Route path={Constants.Routes.GALLERY} element={<Gallery />} />
              <Route path={Constants.Routes.GUEST_INFORMATION} element={<GuestInformation />} />
              <Route path={Constants.Routes.GENERAL_ERROR} element={<GeneralError />} />
              <Route path={Constants.Routes.HOME} element={<Home />} />
              <Route path={Constants.Routes.HOW_TO_FIND_US} element={<HowToFindUs />} />
              <Route path={Constants.Routes.PAY_BALANCE} element={<PayBalance />} />
              <Route path={Constants.Routes.REPORT_CURRENT_BOOKINGS} element={<ReportCurrentBookings />} />
              <Route path={Constants.Routes.REPORT_YEARLY_OCCUPANCY} element={<ReportYearlyOccupancy />} />
              <Route path={'*'} element={<PageNotFound />} />
            </Routes>
          </div>
        </Router>

        <Footer />
      </Elements>
    </>
  );
};

export default App;
