import { ReactElement, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ApproveBookingForm from '../../components/approve-booking-form/approve-booking-form';
import { ApiService } from '../../services/api.service';
import { Constants } from '../../utilities/constants';

const ApproveBooking = (): ReactElement<string, string> => {
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState(false);
  const [approvalError, setApprovalError] = useState('');

  const { id } = useParams();

  const approveBooking = async (approval: any, isApproved: boolean) => {
    setIsSending(true);

    const body = {
      quoteReference: id,
      password: approval.password,
      isApproved
    };

    ApiService.approveBooking(body)
      .then(async result => {
        if (result.status === 403) {
          setApprovalError('Password is incorrect');
        } else if (result.status === 404) {
          setApprovalError(`Unable to find quotation: ${body.quoteReference}`);
        } else {
          // TODO: Would be better to show a result banner rather than navigating to /
          navigate(Constants.Routes.HOME);
        }
        setIsSending(false);

      }).catch(error => {
        console.error(error);
        setApprovalError('Something unexpected went wrong');
        setIsSending(false);
      });
  };

  return (
    <div className="content --padding-md">
      <h1>APPROVE BOOKING</h1>
      <p>Booking reference: {id}</p>

      <ApproveBookingForm
        isSending={isSending}
        approvalError={approvalError}
        approveBooking={approveBooking}
      />
    </div>
  );
};

export default ApproveBooking;
