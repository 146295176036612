import * as types from './action-types';

import { Period } from '../../models/period';
import { Day } from '../../models/day';

type setCalendarAction = {
  type: string;
  days: Map<string, Day>;
}

type SelectHolidayAction = {
  type: string;
  selectedHoliday: Period;
}

type SaveCompletedBookingAction = {
  type: string;
  completedBooking: any;
}

export function setCalendar(days: Map<string, Day>): setCalendarAction {
  return {
    type: types.SET_CALENDAR,
    days
  };
}

export function selectHoliday(selectedHoliday: Period): SelectHolidayAction {
  return {
    type: types.SELECT_HOLIDAY,
    selectedHoliday
  };
}

export function saveCompletedBooking(completedBooking: any): SaveCompletedBookingAction {
  return {
    type: types.SAVE_COMPLETED_BOOKING,
    completedBooking
  };
}
