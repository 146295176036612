import { ReactElement } from 'react';

import './number-picker.scss';

type TextboxProps = {
  label: string,
  id: string,
  name: string,
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  value: string,
  max: number,
  min: number,
  optional?: boolean,
  error?: string
}

const NumberPicker = (props: TextboxProps): ReactElement<string, string> => {
  return (
    <div className="number-picker">
      <label
        className="number-picker__label"
        htmlFor={props.name}>{props.label}{props.optional ? ' (optional)' : ''}:</label>

      <input
        className={`number-picker__input ${props.error ? 'number-picker__input--error' : ''} --margin-top-sm`}
        id={props.id}
        name={props.name}
        type="number"
        onChange={props.handleChange}
        value={props.value}
        max={props.max}
        min={props.min}
      />

      {props.error && <div className='number-picker__error'>{props.error}</div>}

    </div>
  );
};

export default NumberPicker;
