import { ReactElement } from 'react';
import moment from 'moment';

import './footer.scss';

const Footer = (): ReactElement<string, string> => {
  const currentYear = moment().year();

  return (
    <footer>
      <p>Copyright Ⓒ {currentYear} Cleveland Holiday Cottage, Llangennith, Gower. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
