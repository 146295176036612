import moment from 'moment';

import { Constants } from '../../utilities/constants';
import { Day } from '../../models/day';

const currentDay = moment().format(Constants.CALENDAR_KEY_FORMAT);

const createDays = (): Map<string, Day> => {
  const days = new Map<string, Day>();

  // Monday before the 1st Jan
  const yearStart = moment([Constants.YEAR_FROM, 0, 1]).day(1);

  // Sunday after the end of the year
  const yearEnd = moment([Constants.YEAR_TO, 11, 31]).day(7);

  const dayIterator = yearStart;
  let previousDay;
  while (dayIterator.toDate() <= yearEnd.toDate()) {

    const key = dayIterator.format(Constants.CALENDAR_KEY_FORMAT);

    const day = new Day(
      key,
      false,
      (dayIterator.day() === Constants.CHANGEOVER_DAY) ? true : false,

      // Set unavailable if the day is in the past
      key >= currentDay
    );

    days.set(key, day);

    // TODO
    // if (day.isChangeover) {
    //   this.changeovers.set(key, day);
    // }

    // Set previous day
    if (previousDay) {
      day.previous = previousDay;
      previousDay.next = day;
    }
    previousDay = day;

    dayIterator.add(1, 'days');
  }

  return days;
};

const getCalendarWeeks = (month: number, year: number, days?: Map<string, Day>): Day[][] => {
  const weeks: Day[][] = [];

  // Calculate Monday prior to 1st of selected month
  const calendarPeriodStart = moment([year, month, 1]);
  let previousMonday = 1;
  if (previousMonday > calendarPeriodStart.day()) {
    previousMonday -= 7;
  }
  const calendarPeriodStartKey = calendarPeriodStart.day(previousMonday).format(Constants.CALENDAR_KEY_FORMAT);

  // Calculate Sunday after selected month end
  const calendarPeriodEnd = moment([year, month, 1]).add(1, 'months').subtract(1, 'days');
  let nextSunday = 0;
  if (nextSunday < calendarPeriodEnd.day()) {
    nextSunday += 7;
  }
  const calendarPeriodEndKey = calendarPeriodEnd.day(nextSunday).format(Constants.CALENDAR_KEY_FORMAT);

  let week: Day[] = [];

  if (days) {
    let day = days.get(calendarPeriodStartKey);
    while (day && day.key <= calendarPeriodEndKey) {
      week.push(day);
      day = day.next;
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
    }
  }

  return weeks;
};

const createCalendarHeading = (month: number, year: number): string => {
  return moment([year, month]).format(Constants.CALENDAR_MONTH_YEAR_FORMAT);
};

const calcHasPreviousMonth = (month: number, year: number): boolean => {
  const now = moment();
  const currentMonth = now.month();
  const currentYear = now.year();

  return ((year === currentYear && month > currentMonth) || (year > currentYear));
};

const calcHasNextMonth = (month: number, year: number): boolean => {
  return ((year !== Constants.YEAR_TO) || (month < 11));
};

export {
  getCalendarWeeks,
  createDays,
  createCalendarHeading,
  calcHasPreviousMonth,
  calcHasNextMonth
};
