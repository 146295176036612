import { ReactElement, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { ApiService } from '../../../services/api.service';

const YearlyOccupancyReport = (): ReactElement<string, string> => {
  const [report, setreport] = useState(null);

  useEffect(() => {
    ApiService.getReport('yearlyOccupancy').then((result: any) => {
      if (result.status === 200) {
        result.json().then((data: any) => {
          setreport(data.report);
        });
      }
    });
  }, []);

  return (
    <>
      {report && <div className="content --padding-md">
        <h1>{report.reportName}</h1>
        <>

          {
            Object.keys(report.reportData).map((year: any, index: number) => {
              return <div key={index}>
                <h2>{year}</h2>

                <div>
                  {
                    Object.keys(report.reportData[year].months).map((month: any, index2: number) => <p key={index2}>Month: {month} ... Value: {report.reportData[year].months[month]}</p>)
                  }
                </div>

                <p><b>Total days: {report.reportData[year].total}</b></p>

                <p className="--margin-bottom-xl"><b>Total weeks:
                  <NumberFormat
                    value={report.reportData[year].total / 7}
                    displayType={'text'}
                    decimalScale={2}
                  />
                </b></p>
              </div>;
            })
          }
        </>

      </div >
      }
    </>
  );
};

export default YearlyOccupancyReport;
