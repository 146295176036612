import { ReactElement } from 'react';

import AboutRow from '../../components/about-row/about-row';
import HorizontalDivider from '../../components/horizontal-divider/horizontal-divider';
import aboutConfig from '../../assets/data/about-config.json';
import IconGrid from '../../components/icon-grid/icon-grid';

const About = (): ReactElement<string, string> => {
  return (
    <div className="--padding-md">
      <h1>About</h1>

      {aboutConfig.aboutItems.map((aboutItem, i) =>
        <AboutRow aboutItem={aboutItem} key={i} />
      )}

      <h2>General Information</h2>

      <IconGrid icons={aboutConfig.generalIcons} />

      <HorizontalDivider />

      <h2>Facilties</h2>

      <div className="--margin-bottom-xl">
        <IconGrid icons={aboutConfig.facilitiesIcons} />
      </div>
    </div>
  );
};

export default About;
