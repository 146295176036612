import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';

import NumberFormat from 'react-number-format';

import { Period } from '../../models/period';

import './payment-options-form.scss';

type BookablePeriodProps = {
  period: Period,
  paymentOptions: any,
  paymentOption: string,
  onPaymentOptionSelected: (paymentOption: string) => void,
  onNext: () => void;
}

const PaymentOptionsForm = (props: BookablePeriodProps): ReactElement<string, string> => {
  const [paymentOption, setPaymentOption] = useState<string>(props.paymentOption);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setPaymentOption(value);

    props.onPaymentOptionSelected(value);
  };

  const onNext = () => {
    props.onNext();
  };

  return (
    <>
      <form>
        <div className="payment-options --padding-lg">
          {props.paymentOptions && props.paymentOptions.deposit ?
            <>
              <h3>What would you like to pay today to secure your booking?</h3>

              <div>
                <input
                  id="fullAmountOption"
                  type="radio"
                  name="paymentOption"
                  value="fullAmount"
                  checked={paymentOption === 'fullAmount'}
                  onChange={handleChange} />
                <label htmlFor="fullAmountOption">Full amount:&nbsp;
                  <NumberFormat
                    value={props.paymentOptions.fullAmount}
                    thousandSeparator={true}
                    displayType={'text'}
                    prefix={'£'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </label>
              </div>
              <div>

                <input
                  id="depositOption"
                  type="radio"
                  name="paymentOption"
                  value="deposit"
                  checked={paymentOption === 'deposit'}
                  onChange={handleChange} />
                <label htmlFor="depositOption">Deposit:&nbsp;
                  <NumberFormat
                    value={props.paymentOptions.deposit.amount}
                    thousandSeparator={true}
                    displayType={'text'}
                    prefix={'£'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </label>
              </div>

              <div className='--margin-top-lg'>

                <p>You can either pay the full amount today, or you can pay deposit of 30% today and the remaining balance later.</p>

                <ul className="payment-options__list">
                  <li>If you pay the full amount today, there will be nothing further to pay.</li>
                  <li>If you just pay a deposit today, the remaining balance of
                    <b className='--margin-x-xs'>
                      <NumberFormat
                        value={props.paymentOptions.deposit.balanceRequired.amount}
                        thousandSeparator={true}
                        displayType={'text'}
                        prefix={'£'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </b>
                    will be due by <b className="--padding-x-xs">{props.paymentOptions.deposit.balanceRequired.display}</b>
                  </li>
                </ul>
              </div>
            </>
            :
            props.paymentOptions && <>
              <input
                type="hidden"
                value="fullAmount"
                onChange={handleChange} />
              <p className="full-amount">Amount due:&nbsp;
                <NumberFormat
                  value={props.paymentOptions.fullAmount}
                  thousandSeparator={true}
                  displayType={'text'}
                  prefix={'£'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </p>
              <p>Note: The full amount is due today because the arrival date is less than six weeks away.</p>
            </>
          }

        </div>

        <p>By completing this booking you are accepting our <Link to="/files/ClevelandTermsAndConditions.pdf" target="_blank" rel="noopener noreferrer">Terms and Conditions</Link> (opens in a new tab)</p>

        <div className="--align-right">
          <button
            type="button"
            className={"button button--primary "}
            onClick={onNext}>Next step</button>
        </div>
      </form>
    </>
  );
};

export default PaymentOptionsForm;
