import { ReactElement } from 'react';

import './booking-calendar-header.scss';

type BookingCalenderHeaderProps = {
  calendarHeading: string,
  selectPreviousMonth: () => void;
  selectNextMonth: () => void;
  hasPreviousMonth: boolean;
  hasNextMonth: boolean;
}

const BookingCalendarHeader = (props: BookingCalenderHeaderProps): ReactElement<string, string> => {
  return (
    <>
      {/* TODO: <i className="fa fa-chevron-left"></i> */}

      <div className="booking-calendar-header">
        <div
          className={"booking-calendar-header__button booking-calendar-header__button--left" + (props.hasPreviousMonth ? '' : ' --hidden')}
          onClick={props.selectPreviousMonth}>PREV</div>

        <div className="booking-calendar-header__month">{props.calendarHeading}</div>

        <div
          className={"booking-calendar-header__button booking-calendar-header__button--right" + (props.hasNextMonth ? '' : ' --hidden')}
          onClick={props.selectNextMonth}>NEXT</div>
      </div>
    </>
  );
};

export default BookingCalendarHeader;
