import { ReactElement } from 'react';

import './horizontal-divider.scss';

const HorizontalDivider = (): ReactElement<string, string> => {
  return (
    <>
      <div id="divider" className="horizontal-divider"></div>
    </>
  );
};

export default HorizontalDivider;
