import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { Constants } from '../../../utilities/constants';
import { ApiService } from '../../../services/api.service';

import './current-bookings.scss';

const CurrentBookingsReport = (): ReactElement<string, string> => {
  const [bookings, setBookings] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    ApiService.listGoogleCalendarEvents()
      .then((result) => {
        if (result.status === 200) {
          result.json().then((data: any) => {
            console.log(data);

            data.forEach((booking: any) => {
              const arrivalDate = moment(booking.start.date, Constants.CALENDAR_DATE_PICKER_FORMAT);
              const departureDate = moment(booking.end.date, Constants.CALENDAR_DATE_PICKER_FORMAT);

              booking.start.formattedDate = arrivalDate.format(Constants.CALENDAR_LONG_DATE_FORMAT);
              booking.end.formattedDate = departureDate.format(Constants.CALENDAR_LONG_DATE_FORMAT);

              booking.duration = departureDate.diff(arrivalDate, 'days');
              // console.log('duration:', booking.duration);
            });
            setBookings(data);
          });
        }
      }).catch(error => {
        console.error(error);
        navigate(Constants.Routes.GENERAL_ERROR);
      });
  }, []);

  return (
    <>
      <div className="content --padding-md">
        <h1>Current Bookings</h1>

        <table className="bookings-table">
          <thead>
            <tr className="bookings-tr">
              <th className="bookings-th">Arrival date</th>
              <th className="bookings-th">Departure date</th>
              <th className="bookings-th">Duration</th>
            </tr>
          </thead>

          <tbody>
            {bookings && bookings.length && bookings.map((booking: any, i: number) =>
              // <p key={i}>{booking.start.date},{booking.end.date},{booking.summary}</p>
              <tr key={i} className="bookings-tr">
                <td className="bookings-td">{booking.start.formattedDate}</td>
                <td className="bookings-td">{booking.end.formattedDate}</td>
                <td className="bookings-td --align-right">{booking.duration}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div >
    </>
  );
};

export default CurrentBookingsReport;
