import { ReactElement } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import './contact-element.scss';

type ContactElementProps = {
  faLibrary: string,
  icon: IconName,
  label: string,
  href?: string,
  value: string
}

const ContactElement = (props: ContactElementProps): ReactElement<string, string> => {
  return (
    <>
      <div className="contact-element --margin-bottom-xl --align-center">
        <div className="contact-element__icon">
          <FontAwesomeIcon icon={[props.faLibrary as IconPrefix, props.icon]} size="4x" />
        </div>

        <label className="contact-element__label --margin-top-md">
          {props.label}

          <span className="--margin-left-md">
            {props.href ?
              <a href={props.href} target="_blank" rel="noreferrer">{props.value}</a>
              :
              <>{props.value}</>
            }
          </span>
        </label>
      </div>
    </>
  );
};

export default ContactElement;
