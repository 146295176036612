import { ReactElement } from 'react';

const PageNotFound = (): ReactElement<string, string> => {
  return (
    <div className="content --padding-md">
      <h1>Oops! Page not found.</h1>
      <p><a href="/">Return to the home page</a></p>
    </div>
  );
};

export default PageNotFound;
