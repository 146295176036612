import { ReactElement, useState } from 'react';

import ContactElement from '../../components/contact-element/contact-element';
import ContactForm from '../../components/contact-form/contact-form';
import HorizontalDivider from '../../components/horizontal-divider/horizontal-divider';

import { ApiService } from '../../services/api.service';
import { Constants } from '../../utilities/constants';
import ContactDetail from '../../types/contact-detail';
import Email from '../../types/email';

const Contact = (): ReactElement<string, string> => {
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [sendingError, setSendingError] = useState(false);

  const composeEmail = (contactDetail: any) => {
    return {
      from: Constants.CLEVELAND_GOWER_EMAIL,
      to: Constants.CLEVELAND_GOWER_EMAIL,
      subject: 'Cleveland Holiday Cottage - Web site enquiry',
      body: `
  Name: ${contactDetail.name}
  Email: ${contactDetail.email}
  Telephone: ${contactDetail.telephone}
  Enquiry: ${contactDetail.message}`
    };
  };

  const sendMessage = async (contactDetail: ContactDetail): Promise<any> => {
    const email: Email = composeEmail(contactDetail);

    setIsSending(true);

    let result;
    try {
      result = await ApiService.sendMessage(email);
    } catch (e) {
      console.error(e);
    }

    if (result && result.status === 200) {
      setIsSent(true);
    } else {
      console.error('Message sending failed:', result);
      setSendingError(true);
    }

    setIsSending(false);
  };

  return (
    <div className="content --padding-md">
      <h1>Contact</h1>

      <h2>Our Details</h2>

      <ContactElement
        faLibrary="fas"
        icon="phone"
        label="Telephone:"
        value="07902 086208" />

      <ContactElement
        faLibrary="fas"
        icon="envelope"
        label="Email:"
        href={'mailto:' + Constants.CLEVELAND_GOWER_EMAIL}
        value={Constants.CLEVELAND_GOWER_EMAIL} />

      <HorizontalDivider />

      <h2>Social Media</h2>

      {/* TODO set class twitter instagram etc */}

      <ContactElement
        faLibrary="fab"
        icon="twitter"
        label="Twitter:"
        href="https://twitter.com/ClevelandGower"
        value="@clevelandgower" />

      <ContactElement
        faLibrary="fab"
        icon="instagram"
        label="Instagram:"
        href="https://www.instagram.com/ClevelandGower"
        value="@clevelandgower" />

      <ContactElement
        faLibrary="fab"
        icon="facebook"
        label="Facebook:"
        href="https://www.facebook.com/ClevelandGower"
        value="@clevelandgower" />

      <ContactElement
        faLibrary="fab"
        icon="pinterest"
        label="Pinterest:"
        href="https://www.pinterest.com/ClevelandGower"
        value="@clevelandgower" />

      <HorizontalDivider />

      <h2>Send us a message</h2>

      {
        !isSent && <p>We would love to hear from you! Send us a message using the form below:</p>
      }

      <div className="--margin-top-lg">
        <ContactForm
          sendMessage={sendMessage}
          isSending={isSending}
          isSent={isSent}
          sendingError={sendingError}
        />
      </div>
    </div>
  );
};

export default Contact;
