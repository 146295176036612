import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import createReducer from '../reducers/create-reducer';

const store = createStore(
  createReducer,
  applyMiddleware(thunk, logger)
);

export default store;
