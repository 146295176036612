import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPrefix } from '@fortawesome/fontawesome-common-types';

import { selectHoliday, setCalendar } from '../../redux/actions/actions';

import BookablePeriod from '../../components/bookable-period/bookable-period';
import BookingCalendar from '../../components/booking-calendar/booking-calendar';
import CustomBookingEnquiryForm from '../../components/custom-booking-enquiry-form/custom-booking-enquiry-form';
import HorizontalDivider from '../../components/horizontal-divider/horizontal-divider';
import { Day } from '../../models/day';
import { Period } from '../../models/period';
import { Constants } from '../../utilities/constants';
import { ApiService } from '../../services/api.service';
import Overlay from '../../components/overlay/overlay';
import BookingRequest from '../../types/booking-request';

const Bookings = (): ReactElement<string, string> => {
  const [period, setPeriod] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [sendingError, setSendingError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clearSelection = () => {
    if (period) {
      period.clearSelection();
      setPeriod(null);
    }
  };

  const selectPeriod = (selectedDay: Day) => {
    if (period) {
      // Clear the existing selection if the selected day is not in the currently selected period
      if (period.startDay?.isSelected) {
        if (selectedDay !== period.startDay) {
          period.clearSelection();
        }
      }
    }

    const newPeriod = new Period();

    // Work out the first day of the selected period
    let day = selectedDay;
    while (!day.isChangeover && day.isAvailable) {
      day = day.previous;
    }
    const startDay = day;

    // Work out the last day of the selected period
    day = selectedDay;
    while ((day === selectedDay || !day.isChangeover) && day.isAvailable) {
      day = day.next;
    }
    const endDay = day;

    newPeriod.initialise(startDay, endDay);
    newPeriod.toggleSelection();
    newPeriod.calculateRates();

    setPeriod(selectedDay.isSelected ? newPeriod : null);
  };

  const onBook = () => {
    dispatch(selectHoliday(period));
    navigate(Constants.Routes.CREATE_BOOKING);
  };

  const onCalendarCreated = (days: Map<string, Day> = new Map<string, Day>()) => {
    dispatch(setCalendar(days));
  };

  const sendEnquiry = (enquiry: any) => {
    setIsSending(true);
    setSendingError(false);

    const startDay = moment(enquiry.arrivalDate, Constants.CALENDAR_DATE_PICKER_FORMAT);
    const startDayKey = startDay.format(Constants.CALENDAR_KEY_FORMAT);
    const endDayKey = startDay.add(enquiry.nights, 'days').format(Constants.CALENDAR_KEY_FORMAT);

    const bookingRequest: BookingRequest = {
      arrivalDate: startDayKey,
      departureDate: endDayKey,
      duration: enquiry.nights,
      name: enquiry.name,
      email: enquiry.email,
      rate: enquiry.rate
    };

    ApiService.sendBookingRequest(bookingRequest)
      .then((result) => {
        if (result.status !== 200) {
          console.error('Error sending booking request');
          // navigate(Constants.Routes.BOOKING_NOT_FOUND);
          setSendingError(true);
          setIsSending(false);
        } else {
          setIsSending(false);
          setIsSent(true);
        }
      }).catch(error => {
        console.error(error);
        setSendingError(true);
        setIsSending(false);
      });
  };

  return (
    <>
      {isSending && !sendingError &&
        <Overlay
          innerHtml={
            <>
              <div className="--align-center">
                <h1>Sending booking enquiry - please wait</h1>
                <p>
                  <FontAwesomeIcon
                    icon={['fas' as IconPrefix, 'cog']}
                    size="4x"
                    className="spinner" />
                </p>
              </div>
            </>}
        />
      }

      <div className="content --padding-md">
        <h1>Bookings</h1>
        <h2>Book Online</h2>

        <div>

          <p>Select dates below to check our availability and to book online.</p>
          <p>Secure your booking today with a 30% deposit (arrival dates more than 6 weeks away)</p>

          <BookingCalendar
            clearSelection={clearSelection}
            selectPeriod={selectPeriod}
            setCalendar={onCalendarCreated}
          />

          <div className='--margin-top-lg'>
            <BookablePeriod
              arrivalDate={period ? period.startDay.formattedDate : null}
              departureDate={period ? period.endDay.formattedDate : null}
              duration={period ? period.days.length : null}
              discount={period ? period.startDay.discount : null}
              rate={period ? period.rate : null}
              previousRate={period ? period.previousRate : null}
            />
          </div>

          <div className="--margin-top-md --align-right">
            {
              period &&
              <button
                className="button button--primary"
                title="Book the selected holiday"
                onClick={onBook}>BOOK NOW</button>
            }
          </div>
        </div>

        <HorizontalDivider />

        <h2>Custom booking</h2>
        <h3>How it works</h3>

        <p>1. Tell us below when you would like to arrive and how many nights you would like to stay for. We will check availability and work out the cost.</p>
        <p>2. Send us your enquiry using the form below.</p>
        <p>3. We will look at your enquiry and work out if we are able to accept a booking on your chosen dates. If we can, we will send you an email containing a link.</p>
        <p>4. Click on the link in the email. This will take you to our booking page that will allow you to go ahead and complete the booking.</p>

        <CustomBookingEnquiryForm
          sendEnquiry={sendEnquiry}
          isSending={isSending}
          isSent={isSent}
          sendingError={sendingError}
        />

        {sendingError &&
          <div>enquiry sending error</div>
        }
      </div >
    </>
  );
};

export default Bookings;
