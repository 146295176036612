import React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';

import DatePicker from '../form-elements/date-picker/date-picker';
import NumberPicker from '../form-elements/number-picker/number-picker';
import InfoBanner from '../info-banner/info-banner';
import Textbox from '../form-elements/textbox/textbox';

import { Constants } from '../../utilities/constants';
import BookablePeriod from '../bookable-period/bookable-period';
import { Period } from '../../models/period';
import BookablePeriodProps from '../../types/bookable-period';

type CustomBookingEnquiryFormProps = {
  isSending: boolean,
  isSent: boolean,
  sendingError: boolean,
  sendEnquiry: (enquiry: any) => void
}

type CustomBookingEnquiryFormState = {
  arrivalDate: string,
  nights: string,
  name: string,
  email: string,
  arrivalDateError: string,
  nightsError: string,
  nameError: string,
  emailError: string
}

const CustomBookingEnquiryForm = (props: CustomBookingEnquiryFormProps): ReactElement<string, string> => {
  const calendarDays: any = useSelector((state: any) => state.calendarDays);

  const [enquiry, setEnquiry] = useState<CustomBookingEnquiryFormState>({
    arrivalDate: '',
    nights: '',
    name: '',
    email: '',
    arrivalDateError: '',
    nightsError: '',
    nameError: '',
    emailError: ''
  });
  const [period, setPeriod] = useState(null);

  useEffect(() => {

    if (enquiry.arrivalDate && enquiry.nights) {
      const startDay = moment(enquiry.arrivalDate, Constants.CALENDAR_DATE_PICKER_FORMAT);
      const endDay = moment(startDay).add(enquiry.nights, 'days');

      const enquiryPeriod = new Period();
      enquiryPeriod.initialise(
        calendarDays.get(startDay.format(Constants.CALENDAR_KEY_FORMAT)),
        calendarDays.get(endDay.format(Constants.CALENDAR_KEY_FORMAT))
      );

      if (enquiryPeriod.isAvailable()) {
        enquiryPeriod.calculateRates();
      }


      const period: BookablePeriodProps = {};
      period.arrivalDate = startDay.format(Constants.CALENDAR_LONG_DATE_FORMAT);
      period.departureDate = endDay.format(Constants.CALENDAR_LONG_DATE_FORMAT);
      period.duration = parseInt(enquiry.nights);
      period.rate = enquiryPeriod.rate;

      setPeriod(period.rate ? period : null);
    } else {
      setPeriod(null);
    }

  }, [enquiry]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setEnquiry({
      ...enquiry,
      [event.target.name]: value,
      [`${event.target.name}Error`]: ''
    });
  };

  const handleValidation = () => {
    type ErrorInfo = {
      arrivalDateError?: string,
      nightsError?: string,
      nameError?: string,
      emailError?: string
    };
    const errors: ErrorInfo = {};

    if (!enquiry.arrivalDate) {
      errors.arrivalDateError = 'Arrival date cannot be empty';
    }

    if (!enquiry.nights) {
      errors.nightsError = 'Number of nights cannot be empty';
    }

    if (!enquiry.name) {
      errors.nameError = 'Name cannot be empty';
    }
    if (!enquiry.email) {
      errors.emailError = 'Email cannot be empty';
    }

    // TODO check valid date
    // TODO check numeric

    return errors;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    const errors = handleValidation();

    if (Object.keys(errors).length) {
      setEnquiry({
        ...enquiry,
        ...errors
      });
    } else {
      props.sendEnquiry({
        arrivalDate: enquiry.arrivalDate,
        nights: parseInt(enquiry.nights),
        name: enquiry.name,
        email: enquiry.email,
        rate: period.rate
      });
    }
    event.preventDefault();
  };

  return (
    <div className="contact-form --padding-md">
      <>
        {!props.isSent &&
          <form onSubmit={handleSubmit}>

            <div className="--form-group">
              <DatePicker
                label="Arrival date"
                id="arrivalDate"
                name="arrivalDate"
                handleChange={handleChange}
                value={enquiry.arrivalDate}
                error={enquiry.arrivalDateError}
              />
            </div>

            <div className="--form-group">
              <NumberPicker
                label="Number of nights"
                id="nights"
                name="nights"
                handleChange={handleChange}
                value={enquiry.nights}
                error={enquiry.nightsError}
                max={28}
                min={3}
              />
            </div>

            {period &&
              <div className='--margin-bottom-md'>
                <BookablePeriod
                  arrivalDate={period.arrivalDate}
                  departureDate={period.departureDate}
                  duration={period.duration}
                  rate={period.rate}
                />
              </div>
            }

            {!period && enquiry.arrivalDate && enquiry.nights &&
              <div className='--margin-bottom-md --bold'>
                Sorry, the dates you have chosen are currently unavailable. Try selecting a different arrival date or fewer nights.
              </div>
            }

            {period &&
              <>
                <div className="--form-group">
                  <Textbox
                    label="Name"
                    id="name"
                    name="name"
                    handleChange={handleChange}
                    value={enquiry.name}
                    error={enquiry.nameError}
                  />
                </div>

                <div className="--form-group">
                  <Textbox
                    label="Email"
                    id="email"
                    name="email"
                    handleChange={handleChange}
                    value={enquiry.email}
                    error={enquiry.emailError}
                  />
                </div>

                <div className={"--margin-top-md --align-right"}>
                  {!props.isSending && <button className="button button--primary">Send enqury</button>}
                  {props.isSending && !props.sendingError && <div>Sending enqury...</div>}
                </div>
              </>
            }
          </form>
        }

        {props.isSent &&
          <InfoBanner
            isError={props.sendingError}
            innerHtml={
              <>
                <p>Enquiry sent</p>
                <p>Thank you for your enqury. We will be in touch as soon as possible.</p>
              </>}
          />
        }

        {props.sendingError &&
          <InfoBanner
            isError={props.sendingError}
            innerHtml={
              <>
                <p>Sorry - there was an error sending your enqury.</p>
                <p>Please try again or <a href={`mailto:${Constants.CLEVELAND_GOWER_EMAIL}`} className="--white">send us an email</a> instead.</p>
              </>}
          />
        }
      </>
    </div >
  );
};

export default CustomBookingEnquiryForm;
