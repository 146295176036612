import { ReactElement } from 'react';

import './booking-calendar-column-headings.scss';

const calendarHeaders = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const BookingCalendarColumnHeadings = (): ReactElement<string, string> => {
  return (
    <>
      <div className="booking-calendar-column-headings">
        {calendarHeaders.map((header, i) =>
          <div key={i} className="booking-calendar-column-headings__label">
            <strong>{header}</strong>
          </div>
        )}
      </div>
    </>
  );
};

export default BookingCalendarColumnHeadings;
