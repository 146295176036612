import { ReactElement } from 'react';

import './about-row.scss';

type AboutProps = {
  aboutItem: any
}

const AboutRow = (props: AboutProps): ReactElement<string, string> => {

  return (
    <>
      <div className="about-row --margin-bottom-xl">
        <h2>{props.aboutItem.heading}</h2>

        {props.aboutItem.images.map((image: any, i: string) =>
          <img src={`gallery-images/${image.filename}`} alt={image.description} className="about-row__image" key={i} />
        )}

        <ul>
          {props.aboutItem.descriptions.map((description: string, i: string) => <li key={i}>{description}</li>)}
        </ul>
      </div>
    </>
  );
};

export default AboutRow;
