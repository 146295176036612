import { ReactElement } from 'react';

import './date-picker.scss';

type TextboxProps = {
  label: string,
  id: string,
  name: string,
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  value: string,
  optional?: boolean,
  error?: string
}

const DatePicker = (props: TextboxProps): ReactElement<string, string> => {
  return (
    <div className="date-picker">
      <label
        className="date-picker__label"
        htmlFor={props.name}>{props.label}{props.optional ? ' (optional)' : ''}:</label>

      <input
        className={`date-picker__input ${props.error ? 'date-picker__input--error' : ''} --margin-top-sm`}
        id={props.id}
        name={props.name}
        type="date"
        onChange={props.handleChange}
        value={props.value}
      />

      {props.error && <div className='date-picker__error'>{props.error}</div>}

    </div>
  );
};

export default DatePicker;
