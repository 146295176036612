import { ReactElement } from 'react';

import AboutIcon from '../../components/about-icon/about-icon';

import './icon-grid.scss';

type IconGridProps = {
  icons: any
}

const IconGrid = (props: IconGridProps): ReactElement<string, string> => {
  return (
    <>
      <div className="icon-grid">

        {props.icons.map((icon: any, i: any) =>
          <div className="icon-grid__item" key={i}>
            <AboutIcon
              faLibrary={icon.faLibrary}
              icon={icon.type}
              description={icon.description}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default IconGrid;
