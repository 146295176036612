import { ReactElement } from 'react';

import HorizontalDivider from '../../components/horizontal-divider/horizontal-divider';
import banner from '../../assets/images/banner.png';
import visitWales from '../../assets/images/visit-wales-4-star.jpg';

import './home.scss';

const Home = (): ReactElement<string, string> => {
  return (
    <div>
      <img className='home__banner' src={banner} alt='banner' />

      <div className='home__content --padding-md'>

        <div className='home__avatar --margin-xl'>
          <img src='gallery-images/cleveland-holiday-cottage-llangennith.jpg' alt='Cleveland Holiday Cottage, Llangennith, Gower' />
        </div>

        <div className='--margin-xl'>
          <p>Cleveland is a luxurious holiday cottage in the coastal village of Llangennith on the Gower peninsula in Wales, UK.</p>

          <p>Blessed with far-reaching sea and Gower countryside views, Cleveland is situated in very peaceful surroundings
            on the west coast of the Gower, which was the UK&apos;s first Area of Outstanding Natural Beauty, yet the cottage is
            only a 5 minute walk away from the King&apos;s Head (a very popular local pub/restaurant) and PJ&apos;s surf shop (where
            you can hire wetsuits and surfboards).</p>

          <p>The world-famous surfer&apos;s paradise of Rhossili beach is one mile away, which is a very popular surf spot suitable for all abilities and board types.</p>

          <p>Cleveland is ideally located to take advantage of the surfing, walking, cycling, fishing and countless other outdoor pursuits on offer in the Gower, which enjoys some of the most beautiful coastal scenery. A holiday in this Welsh seaside cottage is a delight at any time of the year.</p>
        </div>

        <HorizontalDivider />

        <div className='--align-center --margin-xl'>
          <img className='home__visitWales' src={visitWales} alt='Visit Wales 4 star grading logo' />
          <p>VisitWales 4* Graded</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
