import { ReactElement } from 'react';

import GalleryComponent from '../../components/gallery-component/gallery-component';
import galleryConfig from '../../assets/data/gallery-config.json';

const Gallery = (): ReactElement<string, string> => {

  return (
    <div className="content --padding-md">
      <h1>Gallery</h1>

      <div>
        <GalleryComponent galleryConfig={galleryConfig} />
      </div>
    </div>
  );
};

export default Gallery;
