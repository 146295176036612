import { ReactElement } from 'react';

import './booking-calendar-footer.scss';

const BookingCalendarFooter = (): ReactElement<string, string> => {

  return (
    <div className="booking-calendar-footer">
      <div className="calendar-legend-item-box changeover"></div>
      <div className="calendar-legend-item-meaning">Changeover day</div>

      <div className="calendar-legend-item-box not-available-day"></div>
      <div className="calendar-legend-item-meaning">Not available</div>
    </div>
  );
};

export default BookingCalendarFooter;
