import { ReactElement } from 'react';
import HorizontalDivider from '../../components/horizontal-divider/horizontal-divider';

const HowToFindUs = (): ReactElement<string, string> => {

  return (
    <div className="content --padding-md">
      <h1>How to find us</h1>

      <h2>Address</h2>
      <div>
        <p>Cleveland Holiday Cottage</p>
        <p>Cock Street</p>
        <p>Llangennith</p>
        <p>Gower</p>
        <p>Swansea</p>
        <p>SA3 1JE</p>
      </div>

      <HorizontalDivider />

      <h2>Directions</h2>
      <ul>
        <li>M4, junction 47.</li>
        <li>From the A484 take the B4296 and then the B4295 as far as Llanrhidian. Here take the road to Llangennith.</li>
        <li>When you get to Llangennith, drive into the village. Go straight on past the King&apos;s Head pub and the church, following the signs for the beach, until you come to a mini roundabout.</li>
        <li>At this roundabout take the third exit (right) up the hill, which is signposted as a dead end.</li>
        <li>The property is about 100 yards up the hill from the roundabout on your left hand side.</li>
        <li>Guest parking is permitted anywhere on the gravel drive inside the 8 foot wide wooden gates.</li>
      </ul>

      <HorizontalDivider />

      <h2>Map</h2>

      <div className='--align-center'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m11!1m3!1d514.2721279273078!2d-4.273695605432623!3d51.602332883098896!2m2!1f0!2f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xebd773376ec311d9!2sCleveland+Holiday+Cottage%2C+Llangennith%2C+Gower!5e1!3m2!1sen!2suk!4v1483809676357"
          width="95%" height="800" frameBorder={0} allowFullScreen></iframe>
      </div>
    </div>
  );
};

export default HowToFindUs;
